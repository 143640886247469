import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import { useParams } from 'react-router'
import useAxios from 'axios-hooks'
import { VERIFY_EMAIL } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { IoIosCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'

const VerifyEmailStatus = ({ theme }) => {
  const data = useParams().data
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [statusUI, setStatusUI] = useState(
    <div style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className="text-2xl ">
      Loading...
    </div>,
  )
  const [timer, setTimer] = useState(10)
  const { t } = useTranslation()
  const [
    { data: verifyEmailRes, loading: verifyEmailLoading, error: verifyEmailError },
    captureEmailVerificationCall,
  ] = useAxios(
    {
      url: VERIFY_EMAIL,
      method: 'put',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(verifyEmailLoading)
  }, [verifyEmailLoading])

  useEffect(() => {
    if (verifyEmailRes) {
      if (verifyEmailRes?.success) {
        setStatusUI(
          <div
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className="text-base font-semibold md:text-2xl flex flex-col items-center"
          >
            <IoIosCheckmarkCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-green-500" />
            <p className="mt-2">{'Email Verified !'}</p>
          </div>,
        )
      } else {
        setStatusUI(
          <div
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className="text-base font-semibold md:text-2xl flex flex-col items-center"
          >
            <IoMdCloseCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-red-600 " />
            <p className="mt-2">{t('try_again')} !</p>
          </div>,
        )
      }
    }

    if (verifyEmailError && verifyEmailError.response.status !== 200) {
      setStatusUI(
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className="text-base font-semibold md:text-2xl flex flex-col items-center"
        >
          <IoMdCloseCircleOutline className="w-20 h-20 md:w-40 md:h-40 text-red-600 " />
          <p className="mt-2">{t('try_again')} !</p>
        </div>,
      )
    }
  }, [verifyEmailRes, verifyEmailError])

  useEffect(() => {
    if (!isEmpty(data)) {
      captureEmailVerificationCall({
        data: {
          verify_key: data,
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (!verifyEmailLoading) {
      let myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1)
        }
        if (timer === 0) {
          clearInterval(myInterval)
          window.location.replace('/home')
        }
      }, 1000)

      return () => {
        clearInterval(myInterval)
      }
    }
  }, [timer, verifyEmailLoading])

  return (
    <div style={{ minHeight: '70vh' }} className="flex flex-col items-center justify-center">
      {statusUI}
      {!verifyEmailLoading && (
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className=" md:text-2xl flex flex-col items-center justify-center mt-2 md:mt-10"
        >
          <p className="">
            {t('redirected_in')} {timer}...
          </p>
          <a
            style={{
              background: 'green',
              color: isCustomTemplate ? theme.c7 : '#000',
            }}
            className="border rounded-lg px-3 mt-2"
            href="/home"
          >
            {t('ok')}
          </a>
        </div>
      )}
    </div>
  )
}

export default withTheme(VerifyEmailStatus)
