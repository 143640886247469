import React, { useContext } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { getReleaseDate } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const ViewTransactionDetailModal = ({ onCancel, data, theme }) => {
  const { t } = useTranslation()
  const { isCustomTemplate } = useContext(AppContext)
  return (
    <div
      style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none inset-0"
    >
      <div className="relative w-11/12 md:w-8/12 lg:w-8/12 xl:w-3/5 my-2 mx-auto">
        <div
          style={{
            fontFamily: 'Arial',
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
            border: '',
            background: isCustomTemplate ? theme.c10 : '#151c26',
            borderColor: isCustomTemplate ? theme.c5 : '',
          }}
          className="shadow-lg relative flex flex-col md:w-full p-5 "
        >
          <div
            style={{
              borderColor: isCustomTemplate ? theme.c5 : '',
              color: isCustomTemplate ? theme.c4 : '#fff',
            }}
            className=" justify-center md:text-lg border p-5 rounded-xl"
          >
            <div
              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
              className="md:text-2xl text-lg font-semibold flex justify-center"
            >
              <h4>
                {t('transaction')} {t('details')}
              </h4>
            </div>
            <div className="md:flex justify-between text-md mt-2 md:text-xl md:mt-6 hidden md:visible">
              <p>
                {t('transaction')} {t('id')}
              </p>
              <p className={'text-sm'}>{data.transaction_id}</p>
            </div>
            <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
              <p>
                {t('transaction')} {t('date')}
              </p>
              <p>{getReleaseDate(data.captured_date)}</p>
            </div>
            <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
              <p>
                {t('start')} {t('date')}
              </p>
              <p>{getReleaseDate(data.start_date)}</p>
            </div>
            <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
              <p>
                {t('end')} {t('date')}
              </p>
              <p>{getReleaseDate(data.end_date)}</p>
            </div>
            <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
              <p>{t('status')}</p>
              <p className={`${data.status ? 'text-green-500' : 'text-red-600'}`}>
                {data.status ? t('active') : t('expired')}
              </p>
            </div>
            <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
              <p>{t('subscription_amount')}</p>
              <p>
                {getSymbolFromCurrency(data.currency)}{' '}
                {parseFloat(data?.subscription_amount).toFixed(2)}
              </p>
            </div>
            {data?.discount_amount !== 0 && (
              <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
                <p>{t('discount')}</p>
                <p>{`- ${getSymbolFromCurrency(data.currency)} ${parseFloat(
                  data?.discount_amount,
                ).toFixed(2)}`}</p>
              </div>
            )}

            {data?.tax_amount !== 0 && (
              <div className="flex justify-between text-md mt-2 md:text-xl md:mt-6">
                <p>{t('Tax')} </p>
                <p>{`+ ${getSymbolFromCurrency(data.currency)} ${parseFloat(
                  data?.tax_amount,
                ).toFixed(2)}`}</p>
              </div>
            )}
            <div className="flex justify-between text-md mt-4 md:text-xl md:mt-6">
              <p>{t('total')}</p>
              <p>
                {getSymbolFromCurrency(data.currency)}{' '}
                {parseFloat(data?.transaction_amount).toFixed(2)}
              </p>
            </div>
            <div className="mt-4 md:mt-10 flex justify-center md:text-lg font-bold">
              <button
                style={{
                  backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                  color: isCustomTemplate ? theme.c7 : '#fff',
                }}
                className="px-4 py-1 rounded focus:outline-none"
                onClick={onCancel}
              >
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ViewTransactionDetailModal)
