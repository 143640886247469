import React, { useContext } from 'react'
import { TemplateContext } from '../../context/template'
import '../../components/template/template.scss'
import RowingHeroSection from '../../components/template/rosemary/rowingHeroSection'
import AsymmetricFlow from '../../components/template/rosemary/asymmetricFlow'
import PromotionalLead from '../../components/template/rosemary/promotionalLead'
import PassimCarve from '../../components/template/rosemary/passimCarve'
import InventorySlide from '../../components/template/rosemary/inventorySlide'
import ClassyBanners from '../../components/template/fitness/classyBanners'
import NarrowAdmonition from '../../components/template/fitness/narrowAdmonition'
import DefinityCards from '../../components/template/fitness/definityCards'
import FineTunedGallery from '../../components/template/fitness/fineTunedGallery'
import FlyerIntro from '../../components/template/fitness/flyerIntro'
import MementoSlides from '../../components/template/fitness/mementoSlides'
import WedgedInfo from '../../components/template/fitness/wedgedInfo'
import DecomposedBanners from '../../components/template/gaming/decomposedBanners'
import ExtensiveCarouselRendition from '../../components/template/gaming/extensiveCarouselRendition'
import AdNCarousel from '../../components/template/gaming/adNCarousel'
import ExtensiveCarouselSimple from '../../components/template/gaming/extensiveCarouselSimple'
import TripleCardCarousel from '../../components/template/gaming/tripleCardCarousel'
import BobbingCards from '../../components/template/gaming/bobbingCards'
import DefaultBanner from '../../components/template/default/defaultBanner'
import DefaultSwimlane from '../../components/template/default/defaultSwimlane'
import DefaultArtistSwimlane from '../../components/template/default/defaultArtistSwimlane'
import DefaultVenueSwimlane from '../../components/template/default/defaultVenueSwimlane'
import PassimCarvePlayer from '../../components/template/rosemary/passimCarvePlayer'
import ClassBannerPlayer from '../../components/template/fitness/classBannerPlayer'
import DefaultBannerPlayer from '../../components/template/default/defaultBannerPlayer'
import AudioSwimlane from '../../components/template/default/audioSwimlane'
import AdNCarouselPlayer from '../../components/template/gaming/adNCarouselPlayer'
import DecomposedBannersPlayer from '../../components/template/gaming/decomposedBannersPlayer'
import TopTenSwimlane from '../../components/template/default/topTenSwimlane'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'
import ArticleText from '../../components/template/default/articleText'
import ArticleImage from '../../components/template/default/articleImage'

const TemplateHome = () => {
  const { componentData } = useContext(TemplateContext)

  const getComponent = (key, data) => {
    if (!key) return
    let componentProps = {
      componentData: data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
      onSwap: () => {},
      onDelete: () => {},
      onCopy: () => {},
      onAdd: () => {},
    }
    let componentObj = {
      rowingHeroSection: <RowingHeroSection {...componentProps} />,
      asymmetricFlow: <AsymmetricFlow {...componentProps} />,
      promotionalLead: <PromotionalLead {...componentProps} />,
      passimCarve: <PassimCarve {...componentProps} />,
      passimCarvePlayer: <PassimCarvePlayer {...componentProps} />,
      inventorySlide: <InventorySlide {...componentProps} />,
      classyBanners: <ClassyBanners {...componentProps} />,
      classyBannersPlayer: <ClassBannerPlayer {...componentProps} />,
      narrowAdmonition: <NarrowAdmonition {...componentProps} />,
      definityCards: <DefinityCards {...componentProps} />,
      fineTunedGallery: <FineTunedGallery {...componentProps} />,
      flyerIntro: <FlyerIntro {...componentProps} />,
      mementoSlides: <MementoSlides {...componentProps} />,
      wedgedInfo: <WedgedInfo {...componentProps} />,
      decomposedBanners: <DecomposedBanners {...componentProps} />,
      decomposedBannersPlayer: <DecomposedBannersPlayer {...componentProps} />,
      extensiveCarouselRendition: <ExtensiveCarouselRendition {...componentProps} />,
      adNCarousel: <AdNCarousel {...componentProps} />,
      adNCarouselPlayer: <AdNCarouselPlayer {...componentProps} />,
      extensiveCarousel: <ExtensiveCarouselSimple {...componentProps} />,
      tripleCardCarousel: <TripleCardCarousel {...componentProps} />,
      bobbingCards: <BobbingCards {...componentProps} />,
      defaultBanner: <DefaultBanner {...componentProps} />,
      defaultBannerPlayer: <DefaultBannerPlayer {...componentProps} />,
      defaultSwimlane: <DefaultSwimlane {...componentProps} />,
      newDefaultSwimlane: <NewDefaultSwimlane {...componentProps} />,
      audioSwimlane: <AudioSwimlane {...componentProps} />,
      artistSwimlane: <DefaultArtistSwimlane {...componentProps} />,
      venueSwimlane: <DefaultVenueSwimlane {...componentProps} />,
      topTenSwimlane: <TopTenSwimlane {...componentProps} />,
      articleText: <ArticleText {...componentProps} />,
      articleImage: <ArticleImage {...componentProps} />,
    }

    return componentObj[key]
  }

  return (
    <div>
      {componentData.map((info, index) => getComponent(info?.data?.component_key, info?.data))}
    </div>
  )
}

export default TemplateHome
