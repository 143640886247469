import React, { createRef, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import ReactAudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import CustomImage from '../customImage/customImage'
import { getImage } from '../../utils/helper'
import './audioPlayer.css'
import WaveForm from './waveform'
let flag = false

const AudioPlaylistPlayer = ({ audioSrc, detailObj, onAudioEnd }) => {
  const { accountObj } = useContext(AppContext)

  useEffect(() => {
    flag = false
  }, [])

  const [analyzerData, setAnalyzerData] = useState(null)
  const audioElmRef = createRef()

  const audioAnalyzer = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    const analyzer = audioCtx.createAnalyser()
    analyzer.fftSize = 2048
    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)
    const source = audioCtx.createMediaElementSource(audioElmRef.current.audio.current)
    audioCtx.destination.disconnect()
    analyzer.disconnect()
    source.connect(analyzer)
    source.connect(audioCtx.destination)
    source.onended = () => {
      source.disconnect()
    }

    setAnalyzerData({ analyzer, bufferLength, dataArray })
    flag = true
  }

  return (
    <div className=" w-full h-full relative ">
      {analyzerData && <WaveForm analyzerData={analyzerData} />}
      <CustomImage
        imageUrl={getImage(accountObj?.cf_domain_name, detailObj.thumbnail)}
        imgClass="w-full h-full "
        loaderClass={'h-full'}
        imgAlt={'thumbnail'}
      />

      <ReactAudioPlayer
        ref={audioElmRef}
        autoPlay
        crossOrigin={'anonymous'}
        src={audioSrc}
        onPlay={(e) => {
          if (!flag) {
            audioAnalyzer()
          }
        }}
        className="z-20 absolute bottom-0 text-white"
        style={{ backgroundColor: '#00000090', color: '#fff' }}
        onEnded={() => {
          onAudioEnd()
        }}
        // other props here
      />
    </div>
  )
}

export default AudioPlaylistPlayer
