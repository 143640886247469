import React, { useState } from 'react'
import VideoPlaceholder from '../../assets/video-content.png'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'
import OneIcon from '../../assets/topTenCardIcons/one.svg'
import TwoIcon from '../../assets/topTenCardIcons/two.svg'
import ThreeIcon from '../../assets/topTenCardIcons/three.svg'
import FourIcon from '../../assets/topTenCardIcons/four.svg'
import FiveIcon from '../../assets/topTenCardIcons/five.svg'
import SixIcon from '../../assets/topTenCardIcons/six.svg'
import SevenIcon from '../../assets/topTenCardIcons/seven.svg'
import EightIcon from '../../assets/topTenCardIcons/eight.svg'
import NineIcon from '../../assets/topTenCardIcons/nine.svg'
import TenIcon from '../../assets/topTenCardIcons/ten.png'
import './hoverCard.css'
import LoaderTile from './loaderTile'

const TopTenCard = (props) => {
  const { data, background, isEdit = false, number, thumbnail, theme } = props
  // const navigate = useNavigate()
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [imgLoaded, setImgLoaded] = useState(false)

  const orderImg = {
    1: OneIcon,
    2: TwoIcon,
    3: ThreeIcon,
    4: FourIcon,
    5: FiveIcon,
    6: SixIcon,
    7: SevenIcon,
    8: EightIcon,
    9: NineIcon,
    10: TenIcon,
  }

  const handleClick = () => {
    if (isEdit) return
    if (data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST') {
      customNavigate('DIRECT', `/playlist/${data.content_id}`)
      return
    }
    if (data.video_type === 'MPVP' || data.audio_type === 'MPAP') {
      customNavigate('DIRECT', `/parent-content/${data.content_id}`)
    } else {
      customNavigate('DIRECT', `/content/${data.content_id}`)
    }
  }

  return (
    <div
      className="relative transform transition duration-500 ease z-0  rounded-lg"
      onClick={handleClick}
      style={{
        background: background ? background : theme.c1,
        aspectRatio: '1.2912',
        width: '80%',
      }}
    >
      <div className="flex items-center justify-start relative h-full">
        <img
          src={orderImg[number]}
          alt="orderImg"
          style={{ width: number === 10 ? '63.90%' : '54.88%' }}
          className="h-full object-contain"
        />
        <>
          {!imgLoaded && <LoaderTile aspectRatio={'0.7087'} width={'54%'} />}
          <img
            src={getTemplateImage(thumbnail)}
            className={`absolute right-0 rounded-lg h-full object-center`}
            alt={data?.title}
            style={{ display: !imgLoaded ? 'none' : 'block', aspectRatio: '0.7087', width: '54%' }}
            onLoad={() => setImgLoaded(true)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = VideoPlaceholder
            }}
          />
        </>
      </div>
    </div>
  )
}

export default withTheme(TopTenCard)
