export const ARTIST_LISTING_TITLE = 'SyncNTune ARTISTS'
export const ARTIST_LISTING_DESCRIPTION = 'Meet the most iconic and inspirational music performers.'
export const VENUE_LISTING_TITLE = 'SyncNTune VENUES'
export const VENUE_LISTING_DESCRIPTION = 'Enjoy the music at most lively and contemporary venues.'

export const LG_SIZE = 1200
export const MD_SIZE = 768
export const SM_SIZE = 576

export const DATE_FORMAT_OBJ = {
  DDMMYYYY: 'DD-MM-yyyy',
  MMDDYYYY: 'MM-DD-yyyy',
  YYYYMMDD: 'yyyy-MM-DD',
  YYYYDDMM: 'yyyy-DD-MM',
}

export const SECURITY_SESSION_ID = 'cadjin-session-id'
