import React, { useContext, useEffect, useState } from 'react'
import MinimizeIcon from '../../assets/minimize-icon.svg'
import MiniIcon from '../../assets/custom-form-mini-icon.svg'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import CustomImage from '../customImage/customImage'
import { getImage } from '../../utils/helper'

const CustomFormComponent = (props) => {
  const { currentForm, onFormSubmit } = props
  const { accountObj } = useContext(AppContext)
  const [isMinimized, setIsMinimized] = useState(currentForm.is_minimised)
  const [fieldData, setFieldData] = useState([])
  const [errorData, setErrorData] = useState([])

  useEffect(() => {
    if (!isEmpty(currentForm)) {
      setFieldData(currentForm?.fields?.data)
      setErrorData(Array(currentForm?.fields?.data.length).fill(''))
    }
  }, [currentForm])

  const validateInfo = () => {
    let isFormValid = true
    let tempError = errorData

    for (let i = 0; i < fieldData.length; i++) {
      const element = fieldData[i]
      if (element.is_mandatory && !element.value) {
        isFormValid = false
        tempError[i] = 'Field cannot be empty'
      } else if (
        element.type === 'EMAIL' &&
        (element.value.length < 5 || !element.value.includes('@') || !element.value.includes('.'))
      ) {
        isFormValid = false
        tempError[i] = 'Enter a valid E-mail'
      }
    }
    setErrorData([...tempError])
    return isFormValid
  }

  const handleSave = () => {
    if (!validateInfo()) return
    onFormSubmit(fieldData)
  }

  const handleInputDataChange = (index, event) => {
    const { value } = event.target
    let tempError = errorData
    let tempFieldData = fieldData

    tempFieldData[index].value = value
    tempError[index] = ''

    setFieldData([...tempFieldData])
    setErrorData([...tempError])
  }

  const getFieldInput = (field, index) => {
    if (field.type === 'TEXT_INPUT') {
      return (
        <input
          type="text"
          placeholder="Your text here"
          value={field.value}
          style={{ border: 'solid 0.5px #70707090', background: '#fff' }}
          className="w-full rounded-sm text-sm px-1 py-1"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }
    if (field.type === 'EMAIL') {
      return (
        <input
          type="email"
          placeholder="Your e-mail here"
          value={field.value}
          style={{ border: 'solid 0.5px #70707090', background: '#fff' }}
          className="w-full rounded-sm text-sm px-1 py-1"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }
    if (field.type === 'PHONE') {
      return (
        <input
          type="number"
          placeholder="Your number here"
          value={field.value}
          style={{ border: 'solid 0.5px #70707090', background: '#fff' }}
          className="w-full rounded-sm text-sm px-1 py-1"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }
    if (field.type === 'DROPDOWN') {
      return (
        <select
          value={field.value}
          style={{ border: 'solid 0.5px #70707090', background: '#fff' }}
          className="w-full rounded-sm text-sm px-1 py-1"
          onChange={(event) => handleInputDataChange(index, event)}
        >
          <option value="" disabled>
            Select
          </option>
          {field.options.map((ele, idx) => (
            <option value={ele} key={idx}>
              {ele}
            </option>
          ))}
        </select>
      )
    }
    if (field.type === 'TEXT_AREA') {
      return (
        <textarea
          placeholder="Your text here"
          rows="3"
          value={field.value}
          style={{ border: 'solid 0.5px #70707090', background: '#fff' }}
          className="w-full rounded-sm text-sm px-1 py-1"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }
  }

  return (
    <div>
      {!isEmpty(currentForm) && (
        <div
          className={`flex overflow-x-hidden overflow-y-auto fixed right-4 lg:right-9 ${
            isMinimized ? 'bottom-[5%]' : 'bottom-[5%] w-11/12 lg:w-[21%] xl:w-[17%]'
          }  z-50 outline-none focus:outline-none `}
        >
          {!isMinimized ? (
            <div
              style={{
                backgroundImage: 'linear-gradient(18deg, #fff 13%, #e5e5e5 91%)',
                border: 'solid 0.5px #707070',
                maxHeight: '85vh',
              }}
              className={`relative rounded-lg lg:rounded-xl w-full `}
            >
              <div className="flex justify-end mt-3 mx-3 ">
                <img
                  onClick={() => setIsMinimized(true)}
                  src={MinimizeIcon}
                  alt="MinimizeIcon"
                  className="cursor-pointer"
                />
              </div>
              <div className=" px-4 lg:px-6 w-full text-center flex flex-col items-center justify-center">
                <p style={{ fontSize: 'max(1.5625vw,15px)', width: '67%' }} className="">
                  {currentForm?.heading}
                </p>
                {!isEmpty(currentForm?.thumbnail) && !isEmpty(currentForm?.thumbnail[0]) && (
                  <div
                    className="w-full flex items-center justify-center overflow-hidden rounded-lg"
                    style={{ aspectRatio: 270 / 138 }}
                  >
                    <CustomImage
                      imageUrl={getImage(accountObj?.cf_domain_name, currentForm?.thumbnail[0])}
                      imgClass="z-0 w-full h-full"
                      loaderClass="z-0 w-full h-full"
                      loaderStyle={{
                        background: '#5A5A5A50',
                      }}
                      imgAlt={'class img"'}
                    />
                  </div>
                )}
                {currentForm?.sub_heading && (
                  <p className="w-full text-center text-xs lg:text-base  mt-1">
                    {currentForm?.sub_heading}
                  </p>
                )}
              </div>
              <div className="px-4 lg:px-6 custom-scrollbar-vertical custom-form-field mt-2">
                {fieldData.map((fieldInfo, idx) => (
                  <div key={idx} className={`pb-4 relative`}>
                    <p className="font-semibold">
                      {fieldInfo.name}
                      {fieldInfo.is_mandatory && <span className="text-red-600 px-1">*</span>}
                    </p>
                    {getFieldInput(fieldInfo, idx)}
                    <p className="text-red-600 text-xs">{errorData[idx]}</p>
                  </div>
                ))}
              </div>
              <div className=" py-3 flex justify-center bottom-0 left-0 right-0">
                <button
                  className="2xl:text-xl lg:text-base text-sm lg:w-[unset] text-white 2xl:px-5 xl:px-3 px-3 self-center 2xl:py-1.5 py-1 font-bold border-0"
                  style={{
                    outline: 'none',
                    backgroundImage:
                      'linear-gradient(454deg, rgb(4, 96, 133) -17%, rgb(95, 193, 242) 53%, rgb(4, 96, 133) 108%)',
                    borderRadius: '6px',
                  }}
                  onClick={handleSave}
                >
                  {currentForm?.button_text}
                </button>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setIsMinimized(false)}
              className={`flex justify-center cursor-pointer`}
            >
              <img src={MiniIcon} alt="MiniIcon" className="w-8 h-8 lg:w-14 lg:h-14" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CustomFormComponent
