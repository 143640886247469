import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import { Link } from 'react-router-dom'
import AddSection from '../addSection'
import useTemplateHook from '../templateHook'
import { useTranslation } from 'react-i18next'

const OpaqueHeader = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onAdd,
  onSwap,
}) => {
  const { t } = useTranslation()
  const { data, id } = componentData
  const [profileToggle, setProfileToggle] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const {
    customNavigate,
    isLogin,
    userObj,
    logoutCall,
    setLanguageModal,
    handleSubscription,
    getTemplateImage,
    ProfileIcon,
    isMultilingual,
  } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [searchText, setSearchText] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleSearchButton = () => {
    if (searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      // setOpenMobileSearch(false)
      setSearchText('')
      setOpenMobileMenu(false)
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setSearchText('')
      setOpenMobileMenu(false)
    }
  }

  const handleRedirect = (linkObj) => {
    setOpenMobileMenu(false)
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <>
      <header
        className={` ${isEdit ? 'relative' : 'fixed'} z-50 w-full ${
          isEdit && hoverState && 'component-hover-class'
        } `}
        onMouseEnter={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onEdit={handleModal}
            onDelete={() => onDelete(id)}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <nav style={{ backgroundColor: `${theme.c1}` }} className={` lg:px-14 py-2.5 w-full`}>
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-auto ">
            <img
              src={getTemplateImage(data?.logo_s3key)}
              className="md:mr-3 h-8 sm:h-12 cursor-pointer"
              alt="Gaming logo"
              onClick={() => customNavigate('DIRECT', '/home', isEdit)}
            />
            <div className="flex items-center justify-between lg:order-2 ">
              <div
                style={{ width: isLogin ? '37%' : '33%' }}
                className="relative w-1/3 lg:mr-6 lg:block hidden"
              >
                {/* ---------Search Bar -------- */}
                <input
                  style={{
                    border: 0,
                    borderBottom: `1px solid ${theme.c5}`,
                    outline: 'none',
                    color: 'GrayText',
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => handleSearch(e)}
                  className={`w-full bg-transparent h-10 md:pl-2 md:pr-8 text-sm italic focus:outline-none`}
                  name="search"
                  // placeholder="Search"
                  disabled={isEdit}
                />
                <button
                  type="submit"
                  className="absolute  md:right-1 md:bottom-2 md:mt-2 border-0 bg-transparent"
                  onClick={handleSearchButton}
                >
                  <svg
                    className="text-white h-4 w-4 "
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    style={{ enableBackground: 'new 0 0 56.966 56.966', fill: theme.c5 }}
                    xmlSpace="preserve"
                    width="512px"
                    height="512px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
              {!isLogin ? (
                <div className="hidden lg:flex justify-between items-center">
                  <span
                    onClick={() => handleRedirect(data?.sign_in?.link)}
                    style={{ color: `${theme.c5}` }}
                    className={`block md:mr-6 cursor-pointer ${
                      !isEdit && 'lg:hover:text-blue-700'
                    } `}
                  >
                    {data?.sign_in?.text}
                  </span>

                  <button
                    onClick={() => handleRedirect(data?.cta_button?.link)}
                    style={{ background: theme.c5, color: theme.c7 }}
                    className="border-0 shadow-xl font-medium rounded-full text-sm px-8 md:px-12 md:py-2.5"
                  >
                    {data?.cta_button?.text}
                  </button>
                </div>
              ) : (
                <div
                  className="flex justify-center items-center"
                  onClick={() => {
                    setProfileToggle(!profileToggle)
                    setOpenMobileMenu(false)
                  }}
                  onBlur={(e) => {
                    const currentTarget = e.currentTarget
                    // Give browser time to focus the next element
                    requestAnimationFrame(() => {
                      // Check if the new focused element is a child of the original container
                      if (!currentTarget.contains(document.activeElement)) {
                        setProfileToggle(false)
                        setOpenMobileMenu(false)
                      }
                    })
                  }}
                  tabIndex={3}
                >
                  <div className="relative">
                    <div className="flex justify-center items-center space-x-3 cursor-pointer">
                      <img
                        src={ProfileIcon}
                        alt="ProfileIcon"
                        className="w-7 h-7 md:w-10 md:h-10"
                      />
                      <div className=" font-semibold text-md">
                        <div
                          style={{ color: theme.c5, opacity: '0.85' }}
                          className="flex md:text-base text-sm"
                        >
                          <span>{userObj?.name ? userObj?.name.split(' ')[0] : ''}</span>
                          <svg
                            className={
                              profileToggle
                                ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                            }
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {profileToggle ? (
                      <div
                        style={{
                          backgroundColor: `${theme.c10}90`,
                          borderColor: `${theme.c5}70`,
                          // opacity: "0.85",
                          zIndex: 50,
                        }}
                        className="hidden lg:block absolute right-0 w-48 px-2 py-3 shadow border mt-4"
                      >
                        <ul className="py-1" style={{ color: theme.c4 }} aria-labelledby="dropdown">
                          <li>
                            <Link to="/profile">
                              <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                {t('my_profile')}
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="/watchlist">
                              <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                {t('my_watchlist')}
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="/favourites">
                              <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                {t('my_favorites')}
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="/subscriptions">
                              <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                {t('my_subscriptions')}
                              </p>
                            </Link>
                          </li>
                          <li>
                            <Link to="/transactions">
                              <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                {t('my_transactions')}
                              </p>
                            </Link>
                          </li>
                          <li onClick={handleSubscription}>
                            <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                              {t('change_plan')}
                            </p>
                          </li>
                          {/*<li>*/}
                          {/*  <Link to="/faq">*/}
                          {/*    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">*/}
                          {/*      Help*/}
                          {/*    </p>*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li onClick={logoutCall}>
                            <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                              {t('sign_out')}
                            </p>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              {isMultilingual ? (
                <div className={`${isLogin ? 'mx-6' : 'mx-4'} hidden lg:block`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.491"
                    height="29.982"
                    viewBox="0 0 30.491 29.982"
                    style={{ fill: theme.c5 }}
                    className="cursor-pointer w-6 h-6 mx-2"
                    onClick={() => setLanguageModal(true)}
                  >
                    <path
                      data-name="Path 865"
                      d="M35.311 24H16v19.311h11.18v10.671h19.311v-19.31h-11.18zM24.4 28.574l-3.19 9.1h2.154l.519-1.48h3.297v5.082h-9.147V26.033h15.245v8.639h-4.235l-2.136-6.1zm2.307 5.59H24.6l1.056-3.013zM44.458 36.7v15.25H29.213V36.7z"
                      transform="translate(-16 -24)"
                    />
                    <path
                      data-name="Path 866"
                      d="M282.378 270.637a8.7 8.7 0 0 0 .641.9 5.213 5.213 0 0 1-2.51.6v2.033a7.106 7.106 0 0 0 4.065-1.2 7.106 7.106 0 0 0 4.065 1.2v-2.033a5.2 5.2 0 0 1-2.51-.6 8.676 8.676 0 0 0 .641-.9 10.558 10.558 0 0 0 1.276-3.08h1.1v-2.033h-3.556V264h-2.033v1.525H280v2.033h1.1a10.558 10.558 0 0 0 1.278 3.079zm3.563-3.08a8.45 8.45 0 0 1-.9 2 6.635 6.635 0 0 1-.472.664 6.568 6.568 0 0 1-.44-.613 8.409 8.409 0 0 1-.928-2.053z"
                      transform="translate(-263.23 -248.755)"
                    />
                  </svg>
                </div>
              ) : null}

              <button
                type="button"
                className="lg:hidden flex items-center p-2 ml-1 rounded-lg border-0"
                onClick={() => {
                  setOpenMobileMenu(!openMobileMenu)
                  setProfileToggle(false)
                }}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: theme.c5 }}
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div
              className="hidden justify-between items-center w-full 2xl:ml-[3vw] lg:flex lg:w-auto lg:order-1 "
              id="mobile-menu-2"
            >
              <ul
                style={{ listStyle: 'none', margin: 0, padding: 0 }}
                className="flex flex-col items-center  font-medium lg:flex-row lg:space-x-8 lg:mt-0"
              >
                {data?.heading_1?.text && (
                  <li>
                    <span
                      onClick={() => handleRedirect(data?.heading_1?.link)}
                      style={{ color: `${theme.c5}` }}
                      className={`block py-2 pr-4 pl-3 lg:p-0 cursor-pointer ${
                        !isEdit && 'lg:hover:text-blue-700'
                      } `}
                    >
                      {data.heading_1.text}
                    </span>
                  </li>
                )}
                {data?.heading_2?.text && (
                  <li>
                    <span
                      onClick={() => handleRedirect(data?.heading_2?.link)}
                      style={{ color: `${theme.c5}` }}
                      className={`block py-2 pr-4 pl-3 lg:p-0 cursor-pointer ${
                        !isEdit && 'lg:hover:text-blue-700'
                      } `}
                    >
                      {data?.heading_2?.text}
                    </span>
                  </li>
                )}
                {data?.heading_3?.text && (
                  <li>
                    <span
                      onClick={() => handleRedirect(data?.heading_3?.link)}
                      style={{ color: `${theme.c5}` }}
                      className={`block py-2 pr-4 pl-3 lg:p-0 cursor-pointer ${
                        !isEdit && 'lg:hover:text-blue-700'
                      } `}
                    >
                      {data?.heading_3?.text}
                    </span>
                  </li>
                )}
                {data?.heading_4?.text && (
                  <li>
                    <span
                      onClick={() => handleRedirect(data?.heading_4?.link)}
                      style={{ color: `${theme.c5}` }}
                      className={`block py-2 pr-4 pl-3 lg:p-0 cursor-pointer ${
                        !isEdit && 'lg:hover:text-blue-700'
                      } `}
                    >
                      {data?.heading_4?.text}
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div
              className={openMobileMenu && !profileToggle ? 'w-full' : 'hidden bg-slate-50'}
              style={{ borderBottom: `1.5px solid ${theme.c5}` }}
            >
              <ul className=" divide-y ">
                <li
                  style={{ color: theme.c5 }}
                  className="flex justify-center items-center pt-3 pb-2 px-6"
                >
                  <div className="relative w-full">
                    <input
                      style={{
                        border: `1px solid ${theme.c5}`,
                        outline: 'none',
                        color: 'GrayText',
                      }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => handleSearch(e)}
                      className={`w-full bg-transparent px-2 py-1 rounded-md text-sm italic focus:outline-none`}
                      name="search"
                      placeholder="Search Here"
                      disabled={isEdit}
                    />
                    <button
                      type="submit"
                      style={{ right: '5px' }}
                      className="absolute top-2 bg-transparent"
                      onClick={handleSearchButton}
                    >
                      <svg
                        className="text-white h-4 w-4 "
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56.966 56.966"
                        style={{ enableBackground: 'new 0 0 56.966 56.966', fill: theme.c5 }}
                        xmlSpace="preserve"
                        width="512px"
                        height="512px"
                      >
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                      </svg>
                    </button>
                  </div>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                  onClick={() => handleRedirect(data?.heading_1?.link)}
                >
                  <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                    {data?.heading_1?.text}
                  </p>
                </li>
                {data?.heading_2?.text && (
                  <li
                    style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                    className="flex justify-center items-center"
                    onClick={() => handleRedirect(data?.heading_2?.link)}
                  >
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {data?.heading_2?.text}
                    </p>
                  </li>
                )}

                {data?.heading_3?.text && (
                  <li
                    style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                    className="flex justify-center items-center"
                    onClick={() => handleRedirect(data?.heading_3?.link)}
                  >
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {data?.heading_3?.text}
                    </p>
                  </li>
                )}

                {data?.heading_4?.text && (
                  <li
                    style={{
                      color: theme.c5,
                      borderTop: `1.5px solid ${theme.c5}`,
                    }}
                    className="flex justify-center items-center"
                    onClick={() => handleRedirect(data?.heading_4?.link)}
                  >
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {data?.heading_4?.text}
                    </p>
                  </li>
                )}

                {!isLogin && (
                  <>
                    <li
                      style={{
                        color: theme.c5,
                        borderTop: `1.5px solid ${theme.c5}`,
                      }}
                      className="flex justify-center items-center"
                      onClick={() => handleRedirect(data?.sign_in?.link)}
                    >
                      <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                        {data?.sign_in?.text}
                      </p>
                    </li>
                    <li
                      style={{
                        color: theme.c5,
                        borderTop: `1.5px solid ${theme.c5}`,
                      }}
                      className="flex justify-center items-center"
                    >
                      <button
                        onClick={() => handleRedirect(data?.cta_button?.link)}
                        style={{ background: theme.c5, color: theme.c7 }}
                        className="border-0 shadow-xl font-medium rounded-full text-sm px-8 py-1 my-2"
                      >
                        {data?.cta_button?.text}
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div
              className={
                profileToggle && !openMobileMenu ? 'w-full block lg:hidden' : 'hidden bg-slate-50'
              }
              style={{ borderBottom: `1.5px solid ${theme.c5}` }}
            >
              <ul className=" divide-y ">
                <li style={{ color: theme.c5 }} className="flex justify-center items-center">
                  <Link to="/profile">
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {t('my_profile')}
                    </p>
                  </Link>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                >
                  <Link to="/watchlist">
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {t('my_watchlist')}
                    </p>
                  </Link>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                >
                  <Link to="/favourites">
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {t('my_favorites')}
                    </p>
                  </Link>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                >
                  <Link to="/subscriptions">
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {t('my_subscriptions')}
                    </p>
                  </Link>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                >
                  <Link to="/transactions">
                    <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                      {t('my_transactions')}
                    </p>
                  </Link>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                  onClick={handleSubscription}
                >
                  <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                    {t('change_plan')}
                  </p>
                </li>
                <li
                  style={{ color: theme.c5, borderTop: `1.5px solid ${theme.c5}` }}
                  className="flex justify-center items-center"
                  onClick={logoutCall}
                >
                  <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                    {t('sign_out')}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </header>
      <div className={`${isEdit && 'hidden'} lg:px-14 py-5 w-full`}>&nbsp;</div>
    </>
  )
}

export default withTheme(OpaqueHeader)
