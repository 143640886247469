import React, { useContext, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css'
import '@videojs/themes/dist/fantasy/index.css'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelectors from 'videojs-hls-quality-selector'
import ads from 'videojs-contrib-ads'
import { AdSecondsConvert, getAdUrl } from '../../utils/helper'
import { AppContext } from '../../context/app'
import SkipIcon from '../../assets/skip-ad-arrow.svg'
import { isEmpty } from 'lodash'
import { useInterval } from '../../hooks/useInterval'
import useTemplateHook from '../template/templateHook'

let SKIP_SECONDS = 0

const AdVideoJS = (props) => {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const { options, onReady, cover, adSetData } = props
  const { accountObj } = useContext(AppContext)
  const [skipSeconds, setSkipSeconds] = useState(0)
  const [adDuration, setAdDuration] = useState(0)
  const [currentPlayingAd, setCurrentPlayingAd] = useState({})
  const { customNavigate } = useTemplateHook()

  React.useEffect(() => {
    videojs.registerPlugin('qualityLevels', qualityLevels)
    videojs.registerPlugin('hlsQualitySelectors', hlsQualitySelectors)
    videojs.registerPlugin('ads', ads)
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
        // videoElement.ads()
      }))
      player.ads()

      player.on('contentchange', () => {
        player.trigger('adsready')
      })

      //   player.controls(false)
      for (let i = 0; i < adSetData.length; i++) {
        const adInfo = adSetData[i]

        if (adInfo.ad_location_type === 'pre') {
          playPreRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'post') {
          playPostRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'mid') {
          playMidRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'custom') {
          playCustomRoll(adInfo)
        }
      }

      player.trigger('adsready')
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, adSetData])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const playCustomRoll = (adInfo) => {
    if (!playerRef.current) return
    let customRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let playTime = Math.floor((player.duration() * parseInt(adInfo.ad_location_percentage)) / 100)

      if (!customRollPlayed && currentTime >= playTime) {
        player.ads.startLinearAdMode()
        player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          customRollPlayed = true
          setCurrentPlayingAd(adInfo)
          player.trigger('ads-ad-started')
          handleAdDuration(adInfo?.ad_duration)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }
  const playMidRoll = (adInfo) => {
    if (!playerRef.current) return
    let midRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let halfLength = Math.floor(player.duration() / 2)
      if (!midRollPlayed && currentTime >= halfLength) {
        player.ads.startLinearAdMode()
        player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          midRollPlayed = true
          setCurrentPlayingAd(adInfo)
          player.trigger('ads-ad-started')
          handleAdDuration(adInfo?.ad_duration)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }

  const playPreRoll = (adInfo) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode()
      player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        setCurrentPlayingAd(adInfo)
        player.trigger('ads-ad-started')
        handleAdDuration(adInfo?.ad_duration)

        // player.play()
        // player.muted(false)

        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const playPostRoll = (adInfo) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpostroll', function () {
      player.ads.startLinearAdMode()
      player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        setCurrentPlayingAd(adInfo)
        player.trigger('ads-ad-started')
        handleAdDuration(adInfo?.ad_duration)
        // player.muted(false)
        // player.play()
        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const handleAdDuration = (seconds) => {
    if (!seconds) return
    setAdDuration(seconds)
  }

  useInterval(
    async () => {
      setAdDuration((prev) => prev - 1)
    },
    adDuration > 0 ? 1000 : null,
  )

  const handleSkip = () => {
    if (!playerRef.current) return
    const player = playerRef.current

    player.ads.endLinearAdMode()
    player.controls(true)
    setCurrentPlayingAd({})
  }

  let coverCss = cover ? { objectFit: 'cover' } : { objectFit: '' }

  let positionConfig = {
    TOP_LEFT: 'justify-start items-start ',
    TOP_CENTER: 'justify-center items-start ',
    TOP_RIGHT: 'justify-end items-start ',
    CENTER_LEFT: 'justify-start items-center ',
    CENTER: 'justify-center items-center ',
    CENTER_RIGHT: 'justify-end items-center ',
    BOTTOM_LEFT: { left: '60px' },
    BOTTOM_CENTER: { left: '47%' },
    BOTTOM_RIGHT: {},
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, false)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!currentPlayingAd?.button?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = currentPlayingAd?.button?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div data-vjs-player="" className="">
      <div
        style={{
          //   border: '1px solid red',
          height: 'calc(100%)',
          display: !isEmpty(currentPlayingAd) ? 'flex' : 'none',
        }}
        className="w-full relative flex-col py-4 pl-4 pr-7"
      >
        {currentPlayingAd?.button?.is_enabled &&
          !currentPlayingAd?.button?.position.includes('BOTTOM') && (
            <div
              className={`w-full h-full flex ${positionConfig[currentPlayingAd?.button?.position]}`}
            >
              <button
                style={{
                  fontSize: '12px',
                  backgroundColor: getStyleValue('button_background', 'color', '#ffd91d'),
                  color: getStyleValue('button_text', 'color', '#000'),
                  fontFamily: getStyleValue('button_text', 'font', ''),
                  textAlign: getStyleValue('button_text', 'alignment', ''),
                  height: '34px',
                }}
                className={`border rounded-full px-4 ${getStyleValue(
                  'button_text',
                  'font_format',
                  '',
                )}`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            </div>
          )}
        <div
          style={{ marginTop: 'auto', fontSize: '12px' }}
          className="flex justify-between items-center relative"
        >
          {currentPlayingAd?.button?.is_enabled &&
            currentPlayingAd?.button?.position.includes('BOTTOM') && (
              <button
                style={{
                  fontSize: '12px',
                  background: '#ffd91d',
                  height: '34px',
                  zIndex: 20,
                  //   width: 'fit-content',
                  color: '#000',
                  ...positionConfig[currentPlayingAd?.button?.position],
                }}
                className={`border rounded-full px-4 absolute w-auto ${
                  currentPlayingAd?.button?.position === 'BOTTOM_RIGHT'
                    ? currentPlayingAd?.is_skip_allowed
                      ? 'right-[100px]'
                      : 'right-0'
                    : ''
                }`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            )}
          <div className="flex items-center">
            <p className="">{`Ad `}</p>
            <div
              style={{
                width: '3px',
                height: '3px',
                background: '#fff',
                borderRadius: '12px',
                marginLeft: '3px',
                marginRight: '3px',
              }}
            />
            <p>{AdSecondsConvert(adDuration)}</p>
          </div>

          {currentPlayingAd?.is_skip_allowed && (
            <>
              {skipSeconds > 0 ? (
                <div
                  style={{
                    background: '#00000080',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex justify-center items-center cursor-pointer"
                >
                  <p className="px-1 mr-1" style={{ borderRight: '0.5px solid #e3e3e3' }}>
                    Skip after
                  </p>
                  <p>{skipSeconds}</p>
                </div>
              ) : (
                <div
                  style={{
                    background: '#fff',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex text-center justify-center items-center text-black cursor-pointer"
                  onClick={handleSkip}
                >
                  <p className="px-1 mr-1">Skip Ad</p>
                  <img
                    src={SkipIcon}
                    alt="SkipIcon"
                    style={{
                      width: '9.8px',
                      height: '13px',
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* </div> */}
      </div>
      <video
        ref={videoRef}
        style={coverCss}
        className="video-js vjs-fill vjs-big-play-centered object-cove vjs-theme-fantasy"
      />
    </div>
  )
}

export default AdVideoJS
