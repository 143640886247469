import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { AppContext } from '../../context/app'
import './contentDetail.css'
import { withTheme } from 'theming'
import ReviewsListing from '../../components/reviewRating'
import RelatedVideos from './relatedVideos'
import CommentListing from '../../components/comments'
import { useTranslation } from 'react-i18next'

const menuBtnStyles = {
  fontSize: 'max(1.2vw,16px)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  //   background: 'gray',
  paddingBottom: '10px',
  borderBottom: '3px solid',
}

const ContentDetailBottom = ({ theme, detailObj }) => {
  const contentId = useParams().contentId
  const { t } = useTranslation()
  const { modulePermissions } = useContext(AppContext)

  const [selectedTab, setSelectedTab] = useState('related_contents')

  const tabs = [
    {
      title: t('related_contents'),
      id: 'related_contents',
    },
    ...(modulePermissions?.rating
      ? [
          {
            title: t('reviews'),
            id: 'reviews',
          },
        ]
      : []),
    {
      title: t('comments'),
      id: 'comments',
    },
  ]

  return (
    <div className="xl:mt-[-10vh] lg:mt-[-8vh]">
      <div className=" 2xl:px-24 xl:px-14 md:px-10 px-4 xl:h-[10vh] lg:h-[8vh] h-[7vh] w-full">
        <div
          style={{
            borderBottom: '1px solid #707070',
            overflowX: 'auto',
          }}
          className="relative h-full no-scrollbar"
        >
          <div className="flex justify-start items-end h-full md:gap-x-[min(5vw,71px)] gap-x-[43px] md:w-full w-[560px] overflow-x-hidden">
            {tabs.map((option) => (
              <button
                key={option.id}
                style={{
                  ...menuBtnStyles,
                  color: selectedTab === option.id ? '#09e9e9' : '#cecece',
                  borderBottomColor: selectedTab === option.id ? '#09e9e9' : 'transparent',
                }}
                onClick={() => setSelectedTab(option.id)}
              >
                {option.title}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: '48vh',
          border: '2px solid red',
          borderColor: 'transparent',
          maxHeight: '150vh',
          overflow: 'auto',
        }}
        className="w-full relative text-white 2xl:px-24 xl:px-14 md:px-10 px-4"
      >
        {selectedTab === 'related_contents' && (
          <RelatedVideos contentId={contentId} theme={theme} />
        )}
        {modulePermissions?.rating && selectedTab === 'reviews' && (
          <ReviewsListing type={'content'} id={contentId} title={detailObj?.title} theme={theme} />
        )}
        {selectedTab === 'comments' && (
          <CommentListing type={'content'} id={contentId} theme={theme} />
        )}
      </div>
    </div>
  )
}

export default withTheme(ContentDetailBottom)
