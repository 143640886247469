import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AppContext } from '../../context/app'
import { SubscriptionContext } from '../../context/subscription'
import { getTemplateRoutes } from './templateFunctions'
import ProfileIcon from '../../assets/profile-icon.png'
import LeftArrow from '../../assets/left-arrow.svg'
import RightArrow from '../../assets/right-arrow.svg'
import PlusIcon from '../../assets/plus-icon.svg'
import EditIcon from '../../assets/edit-icon.svg'
import TrashIcon from '../../assets/trash-icon.svg'
import VideoPlaceholder from '../../assets/video-content.png'
import UpArrow from '../../assets/up-arrow.svg'
import DownArrow from '../../assets/down-arrow.svg'
import FitnessPrev from '../../assets/fitness-prev-icon.svg'
import FitnessNext from '../../assets/fitness-next-icon.svg'
import SmallLiveImage from '../../assets/live-image.png'
import PrevArrow from '../../assets/prev-arrow-no-outline.svg'
import NextArrow from '../../assets/next-arrow-no-outline.svg'
import BannerPlaceholder from '../../assets/banner.jpg'

import FBLogo from '../../assets/social/facebook-logo.png'
import InstaLogo from '../../assets/social/instagram-logo-white.png'
import TGLogo from '../../assets/social/telegram-white.png'
import YTLogo from '../../assets/social/youtube-white.png'
import TwitterLogo from '../../assets/social/twitter-white.png'
import BitchuteLogo from '../../assets/social/bitchute-white-logo.png'
import RumbleLogo from '../../assets/social/rumble.png'
import BoxcastLogo from '../../assets/social/boxcast-icon.png'
import TikTokLogo from '../../assets/social/tiktok-white.png'
import EmailIcon from '../../assets/email-icon.svg'
import BasicPlaceholder from '../../assets/basic-placehoder.jpg'
import PhoneIcon from '../../assets/phone-icon.svg'

import SearchIconBlue from '../../assets/search-icon-blue.svg'
import SearchIconWhite from '../../assets/search-icon-white.svg'
import HamburgerIcon from '../../assets/hamburger-icon-white.svg'
import UIMediaStarLogo from '../../assets/profile-icon-red.svg'
import { getExternalLink } from './templateFunctions'
import { LG_SIZE, SM_SIZE } from '../../utils/constants'
import {
  CONTENT_FILTERING,
  ARTIST_LISTING,
  VENUE_LISTING,
  INTERNAL_ASSET_API,
} from '../../utils/apiUrls'
import FBDefaultLogo from '../../assets/social/facebook-default.png'
import InstaDefaultLogo from '../../assets/social/instagram-default.png'
import TGDefaultLogo from '../../assets/social/telegram-default.png'
import YTDefaultLogo from '../../assets/social/youtube-default.png'
import TwitterDefaultLogo from '../../assets/social/twitter-default.png'
import BitchuteDefaultLogo from '../../assets/social/bitchute-default.png'
import RumbleDefaultLogo from '../../assets/social/rumble-default.png'
import BoxcastDefaultLogo from '../../assets/social/boxcast-default.png'
import TikTokDefaultLogo from '../../assets/social/tiktok-default.png'
import DMCALogo from '../../assets/DMCA_Logo.png'
import { viewsFormatter } from '../../utils/helper'
import PlaylistPlayer from '../../container/player/playlistPlayer'
import Player from '../../container/player/player'
import ChannelPlayer from '../../container/player/channelPlayer'
import TrailerPlayer from '../../container/player/trailerPlayer'
import PlayIcon from '../../assets/play-circle.svg'
import AndroidStore from '../../assets/mobileApps/androidStore.png'
import AppleStore from '../../assets/mobileApps/appleStore.png'
import AndroidTv from '../../assets/tvAppIcons/androidTv.png'
import AppleTv from '../../assets/tvAppIcons/appleTv.png'
import RokuTv from '../../assets/tvAppIcons/rokuTv.png'
import FireTv from '../../assets/tvAppIcons/fireTv.png'
import SamsungTv from '../../assets/tvAppIcons/samsungTv.png'
import LGTv from '../../assets/tvAppIcons/LGTv.png'

const useTemplateHook = () => {
  const navigate = useNavigate()
  const {
    isLogin,
    userObj,
    logoutCall,
    accountObj,
    setLanguageModal,
    isBrowser,
    setPopupModalLink,
    setPopUpTitle,
  } = useContext(AppContext)
  const { setOpenSubscriptionModal, setSubscriptions } = useContext(SubscriptionContext)
  const location = useLocation()

  const handleRedirect = (type, linkObj, isEdit, popUpTitle = '') => {
    if (isEdit) return
    if (type === 'DIRECT') {
      navigate(linkObj)
      return
    }
    if (!linkObj?.type) return

    if (linkObj?.type === 'EXTERNAL') {
      const url = linkObj?.data?.url.includes('http')
        ? linkObj?.data?.url
        : `http://${linkObj?.data?.url}`

      if (linkObj.data?.popup_enabled) {
        setPopupModalLink(url)
        setPopUpTitle(popUpTitle)
        return
      }

      window.open(url, '_blank')
      return
    }
    navigate(getTemplateRoutes(linkObj))
  }

  const checkActiveClass = (linkObj, isEdit) => {
    if (isEdit || !linkObj?.type || linkObj?.type === 'EXTERNAL') return false
    return getTemplateRoutes(linkObj) === window.location.href.replace(window.location.origin, '')
  }

  const getTemplateImage = (s3Key) => {
    if (s3Key.includes('common-template-assets')) {
      return INTERNAL_ASSET_API + '/' + s3Key
    }
    return accountObj?.cf_domain_name + '/' + s3Key
  }

  const handleSubscription = () => {
    setSubscriptions([])
    setOpenSubscriptionModal(true)
  }

  const fitnessHeaderPadding = !(location.pathname === '/' || location.pathname === '/home')
  return {
    customNavigate: handleRedirect,
    checkActiveClass,
    isLogin,
    userObj,
    logoutCall,
    setLanguageModal,
    handleSubscription,
    getTemplateImage,
    ProfileIcon,
    LeftArrow,
    RightArrow,
    PlusIcon,
    EditIcon,
    TrashIcon,
    VideoPlaceholder,
    fitnessHeaderPadding,
    UpArrow,
    DownArrow,
    FitnessPrev,
    FitnessNext,
    SmallLiveImage,
    PrevArrow,
    NextArrow,
    isBrowser,
    BannerPlaceholder,
    FBLogo,
    InstaLogo,
    TGLogo,
    YTLogo,
    TwitterLogo,
    BitchuteLogo,
    RumbleLogo,
    BoxcastLogo,
    TikTokLogo,
    EmailIcon,
    BasicPlaceholder,
    PhoneIcon,
    SearchIconBlue,
    SearchIconWhite,
    HamburgerIcon,
    UIMediaStarLogo,
    getExternalLink,
    LG_SIZE,
    SM_SIZE,
    CONTENT_FILTER_XAVIER: CONTENT_FILTERING,
    ARTIST_LISTING,
    VENUE_LISTING,
    FBDefaultLogo,
    InstaDefaultLogo,
    TGDefaultLogo,
    YTDefaultLogo,
    TwitterDefaultLogo,
    BitchuteDefaultLogo,
    RumbleDefaultLogo,
    BoxcastDefaultLogo,
    TikTokDefaultLogo,
    DMCALogo,
    viewsFormatter,
    Player,
    PlaylistPlayer,
    ChannelPlayer,
    TrailerPlayer,
    PlayIcon,
    isMultilingual: accountObj?.allowed_languages
      ? accountObj?.allowed_languages.length > 1
      : false,
    AndroidStore,
    AppleStore,
    AndroidTv,
    AppleTv,
    RokuTv,
    FireTv,
    SamsungTv,
    LGTv,
  }
}

export default useTemplateHook
