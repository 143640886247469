import React, { useEffect, useRef, useState } from 'react'
import SelectedRadio from '../../assets/player/radio-btn-selected.svg'
import UnSelectedRadio from '../../assets/player/radio-btn-unselected.svg'
import SpeedIcon from '../../assets/player/playback-speed-icon.svg'
import QualityIcon from '../../assets/player/quality-icon.svg'
import SubtitleIcon from '../../assets/player/subtitle-icon.svg'
import { isEmpty } from '../../utils/helper'

const SettingsMenu = ({ playerRef, setShowSettingMenu }) => {
  const settingRef = useRef(null)
  const [selectedSetting, setSelectedSetting] = useState('Resolution')
  const [selectedSpeed, setSelectedSpeed] = useState(1)
  const [qualityLevelsMaster, setQualityLevelsMaster] = useState([])
  const [currentPlayingQuality, setCurrentPlayingQuality] = useState(0)
  const [textTrackMaster, setTextTrackMaster] = useState([])
  const [currentTextTrack, setCurrentTextTrack] = useState('')
  const [audioTrackMaster, setAudioTrackMaster] = useState([])
  const [currentAudioTrack, setCurrentAudioTrack] = useState('')

  useEffect(() => {
    settingRef.current.focus()
  }, [])

  useEffect(() => {
    if (playerRef.current) {
      let player = playerRef.current
      let qualityLevels = player.qualityLevels()
      let textTracks = player.textTracks()
      let audioTracks = player.audioTracks()
      //   [...player.qualityLevels().levels_].map((info) => info.height),

      setQualityLevelsMaster(
        [...qualityLevels.levels_].map((info) => info.height).sort((a, b) => b - a),
      )
      setSelectedSpeed(player.playbackRate())
      setCurrentPlayingQuality(qualityLevels[qualityLevels.selectedIndex]?.height)

      for (let i = 0; i < textTracks.length; i++) {
        let track = textTracks[i]

        if (track.kind === 'subtitles' && track.mode === 'showing') {
          setCurrentTextTrack(track.language)
        }
      }

      setTextTrackMaster(
        [...textTracks.tracks_].filter((info) => info.kind === 'subtitles').map((ele) => ele),
      )

      for (let i = 0; i < audioTracks.length; i++) {
        let track = audioTracks[i]

        if (track.enabled) {
          setCurrentAudioTrack(track.language)
        }
      }

      setAudioTrackMaster([...audioTracks.tracks_].map((ele) => ele))
    }
  }, [playerRef])

  const changePlaybackRate = (rate) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    player.playbackRate(rate)
    setSelectedSpeed(rate)
  }

  const changeVideoQuality = (quality) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let qualityLevels = player.qualityLevels()

    if (qualityLevels) {
      let chosenQualityIndex = [...qualityLevels.levels_]
        .map((info) => info.height)
        .findIndex((q) => q === quality)

      for (let i = 0; i < qualityLevels.length; i++) {
        let qualityLevel = qualityLevels[i]
        qualityLevel.enabled = i === chosenQualityIndex ? true : false
      }
      qualityLevels.selectedIndex_ = chosenQualityIndex
      qualityLevels.trigger({ type: 'change', selectedIndex: chosenQualityIndex })
      setCurrentPlayingQuality(quality)
      console.log('chosenQualityIndex', quality, chosenQualityIndex)
    }
  }

  const changeTextTrack = (language) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let textTracks = player.textTracks()
    for (let i = 0; i < textTracks.length; i++) {
      let track = textTracks[i]

      track.mode = 'disabled'
      if (track.kind === 'subtitles' && track.language === language) {
        track.mode = 'showing'
      }
    }
    setCurrentTextTrack(language)
  }

  const changeAudioTrack = (language) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let audioTracks = player.audioTracks()
    for (let i = 0; i < audioTracks.length; i++) {
      let track = audioTracks[i]

      track.enabled = false
      if (track.language === language) {
        track.enabled = true
      }
    }
    setCurrentAudioTrack(language)
  }

  const handleChangeSetting = (type) => {
    setShowSettingMenu(true)
    setSelectedSetting(type)
  }

  return (
    <div
      style={{
        background: '#00000090',
        width: '332px',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 30,
      }}
      className="absolute rounded-lg left-0 lg:left-auto bottom-[45px] lg:bottom-[50px] h-[140px] lg:h-[160px] right-0 flex py-1.5"
      onBlur={() => setShowSettingMenu(false)}
      tabIndex={-1}
      ref={settingRef}
    >
      <div style={{ borderRight: '0.75px solid #ffffff60' }} className="w-1/2 h-full py-2 text-sm">
        <div
          className={` ${
            selectedSetting === 'Resolution' ? 'opacity-100' : 'opacity-60'
          } flex items-center cursor-pointer mb-2 pl-4 setting-main-menu`}
        >
          <img src={QualityIcon} alt="QualityIcon" className="w-4 h-4" />
          <p
            className={`text-white ml-2  font-semibold `}
            onClick={() => handleChangeSetting('Resolution')}
          >
            Resolution
          </p>
        </div>
        <div
          className={` ${
            selectedSetting === 'Speed' ? 'opacity-100' : 'opacity-60'
          } flex items-center cursor-pointer mb-2 pl-4 setting-main-menu`}
        >
          <img src={SpeedIcon} alt="SpeedIcon" className="w-4 h-4" />
          <p
            className={`text-white ml-2  font-semibold `}
            onClick={() => handleChangeSetting('Speed')}
          >
            Playback Speed
          </p>
        </div>

        <div
          className={` ${
            selectedSetting === 'Subtitle' ? 'opacity-100' : 'opacity-60'
          } flex items-center cursor-pointer mb-2 pl-4 setting-main-menu`}
        >
          <img src={SubtitleIcon} alt="SubtitleIcon" className="w-4 h-4" />
          <p
            className={`text-white ml-2  font-semibold `}
            onClick={() => handleChangeSetting('Subtitle')}
          >
            Subtitle
          </p>
        </div>

        <div
          className={` ${
            selectedSetting === 'Language' ? 'opacity-100' : 'opacity-60'
          } flex items-center cursor-pointer mb-2 pl-4 setting-main-menu`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.491"
            height="29.982"
            viewBox="0 0 30.491 29.982"
            style={{ fill: '#fff' }}
            className="w-4 h-4"
          >
            <path
              data-name="Path 865"
              d="M35.311 24H16v19.311h11.18v10.671h19.311v-19.31h-11.18zM24.4 28.574l-3.19 9.1h2.154l.519-1.48h3.297v5.082h-9.147V26.033h15.245v8.639h-4.235l-2.136-6.1zm2.307 5.59H24.6l1.056-3.013zM44.458 36.7v15.25H29.213V36.7z"
              transform="translate(-16 -24)"
            />
            <path
              data-name="Path 866"
              d="M282.378 270.637a8.7 8.7 0 0 0 .641.9 5.213 5.213 0 0 1-2.51.6v2.033a7.106 7.106 0 0 0 4.065-1.2 7.106 7.106 0 0 0 4.065 1.2v-2.033a5.2 5.2 0 0 1-2.51-.6 8.676 8.676 0 0 0 .641-.9 10.558 10.558 0 0 0 1.276-3.08h1.1v-2.033h-3.556V264h-2.033v1.525H280v2.033h1.1a10.558 10.558 0 0 0 1.278 3.079zm3.563-3.08a8.45 8.45 0 0 1-.9 2 6.635 6.635 0 0 1-.472.664 6.568 6.568 0 0 1-.44-.613 8.409 8.409 0 0 1-.928-2.053z"
              transform="translate(-263.23 -248.755)"
            />
          </svg>
          <p
            className={`text-white ml-2  font-semibold `}
            onClick={() => handleChangeSetting('Language')}
          >
            Language
          </p>
        </div>
      </div>
      <div className="w-1/2 h-full py-2 text-sm">
        {selectedSetting === 'Speed' && (
          <div className="w-full h-full pl-4 scrollbar-class">
            <div
              className="flex items-center cursor-pointer mb-2"
              onClick={() => changePlaybackRate(0.5)}
            >
              <img
                src={selectedSpeed === 0.5 ? SelectedRadio : UnSelectedRadio}
                alt="SelectedRadio"
                className="w-4 h-4"
              />
              <p
                className={`text-white ${
                  selectedSpeed === 0.5 ? 'opacity-100' : 'opacity-60'
                }   font-semibold  ml-2`}
              >
                0.5x
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer mb-2"
              onClick={() => changePlaybackRate(0.75)}
            >
              <img
                src={selectedSpeed === 0.75 ? SelectedRadio : UnSelectedRadio}
                alt="SelectedRadio"
                className="w-4 h-4"
              />
              <p
                className={`text-white ${
                  selectedSpeed === 0.75 ? 'opacity-100' : 'opacity-60'
                }   font-semibold ml-2`}
              >
                0.75x
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer mb-2"
              onClick={() => changePlaybackRate(1)}
            >
              <img
                src={selectedSpeed === 1 ? SelectedRadio : UnSelectedRadio}
                alt="SelectedRadio"
                className="w-4 h-4"
              />
              <p
                className={`text-white ${
                  selectedSpeed === 1 ? 'opacity-100' : 'opacity-60'
                }   font-semibold ml-2`}
              >
                1x (Normal)
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer mb-2"
              onClick={() => changePlaybackRate(1.25)}
            >
              <img
                src={selectedSpeed === 1.25 ? SelectedRadio : UnSelectedRadio}
                alt="SelectedRadio"
                className="w-4 h-4"
              />
              <p
                className={`text-white ${
                  selectedSpeed === 1.25 ? 'opacity-100' : 'opacity-60'
                }   font-semibold ml-2`}
              >
                1.25x
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => changePlaybackRate(1.5)}
            >
              <img
                src={selectedSpeed === 1.5 ? SelectedRadio : UnSelectedRadio}
                alt="SelectedRadio"
                className="w-4 h-4"
              />
              <p
                className={`text-white ${
                  selectedSpeed === 1.5 ? 'opacity-100' : 'opacity-60'
                }   font-semibold ml-2`}
              >
                1.5x
              </p>
            </div>
          </div>
        )}
        {selectedSetting === 'Resolution' && (
          <div className="w-full h-full pl-4 scrollbar-class">
            {!isEmpty(qualityLevelsMaster) ? (
              qualityLevelsMaster.map((info, index) => (
                <div
                  className="flex items-center cursor-pointer mb-2"
                  onClick={() => changeVideoQuality(info)}
                  key={index}
                >
                  <img
                    src={currentPlayingQuality === info ? SelectedRadio : UnSelectedRadio}
                    alt="SelectedRadio"
                    className="w-4 h-4"
                  />
                  <p
                    className={`text-white ${
                      currentPlayingQuality === info ? 'opacity-100' : 'opacity-60'
                    }   font-semibold  ml-2`}
                  >
                    {info}p
                  </p>
                </div>
              ))
            ) : (
              <p className="w-full text-center text-white font-semibold opacity-60">
                No Resolution
              </p>
            )}
          </div>
        )}
        {selectedSetting === 'Subtitle' && (
          <div className="w-full h-full pl-4 scrollbar-class">
            {!isEmpty(textTrackMaster) ? (
              <>
                {textTrackMaster.map((info, index) => (
                  <div
                    className="flex items-center cursor-pointer mb-2"
                    onClick={() => changeTextTrack(info.language)}
                    key={index}
                  >
                    <img
                      src={currentTextTrack === info.language ? SelectedRadio : UnSelectedRadio}
                      alt="SelectedRadio"
                      className="w-4 h-4"
                    />
                    <p
                      className={`text-white ${
                        currentTextTrack === info.language ? 'opacity-100' : 'opacity-60'
                      }   font-semibold  ml-2`}
                    >
                      {info.label}
                    </p>
                  </div>
                ))}
                <div
                  className="flex items-center cursor-pointer mb-2"
                  onClick={() => changeTextTrack('')}
                >
                  <img
                    src={currentTextTrack === '' ? SelectedRadio : UnSelectedRadio}
                    alt="SelectedRadio"
                    className="w-4 h-4"
                  />
                  <p
                    className={`text-white ${
                      currentTextTrack === '' ? 'opacity-100' : 'opacity-60'
                    }   font-semibold  ml-2`}
                  >
                    None
                  </p>
                </div>
              </>
            ) : (
              <p className="w-full text-center text-white font-semibold opacity-60">No Subtitle</p>
            )}
          </div>
        )}
        {selectedSetting === 'Language' && (
          <div className="w-full h-full pl-4 scrollbar-class">
            {!isEmpty(audioTrackMaster) ? (
              audioTrackMaster.map((info, index) => (
                <div
                  className="flex items-center cursor-pointer mb-2"
                  onClick={() => changeAudioTrack(info.language)}
                  key={index}
                >
                  <img
                    src={currentAudioTrack === info.language ? SelectedRadio : UnSelectedRadio}
                    alt="SelectedRadio"
                    className="w-4 h-4"
                  />
                  <p
                    className={`text-white ${
                      currentAudioTrack === info.language ? 'opacity-100' : 'opacity-60'
                    }   font-semibold  ml-2`}
                  >
                    {info.label}
                  </p>
                </div>
              ))
            ) : (
              <p className="w-full text-center text-white font-semibold opacity-60">No Language</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsMenu
