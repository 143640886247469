import React, { useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import './globalStyles.css'
import './swimlane.css'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import ContentCardAudio from '../../contentCard/contentCardAudio'
import { encodeURI } from 'js-base64'

const AudioSwimlane = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { customNavigate, isBrowser, LG_SIZE, SM_SIZE, CONTENT_FILTER_XAVIER } = useTemplateHook()
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [contentData, setContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(6)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(4)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data.content_data) || !isEmpty(data.auto_payload)) {
      let filterPayload =
        data.type === 'STATIC'
          ? { content_ids: data.content_data }
          : {
              system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
              custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
              audio_types: ['SPA', 'MPAP', 'MPAC', 'PLAYLIST'],
            }

      contentListCall({
        data: {
          filters: filterPayload,
          page: 1,
          order_by:
            data.type === 'STATIC'
              ? ''
              : data?.order_by === 'title'
              ? `title.${language}`
              : data?.order_by,
          reverse: data.type === 'STATIC' ? '' : data?.reverse,
          page_size: 15,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleSeeMore = () => {
    let payloadObj = {
      filters: {},
      order_by:
        data.type === 'STATIC'
          ? ''
          : data?.order_by === 'title'
          ? `title.${language}`
          : data?.order_by,
      reverse: data.type === 'STATIC' ? '' : data?.reverse,
    }
    payloadObj.filters =
      data.type === 'STATIC'
        ? {
            content_ids: data.content_data,
          }
        : {
            system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
          }
    const encoded = encodeURI(JSON.stringify(payloadObj))
    customNavigate('DIRECT', `/content/filter/${encodeURI(data.name)}?filters=${encoded}`, isEdit)
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-6 pb-8  lg:py-8
  `}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
        zIndex: 1,
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="flex items-baseline">
        <p
          // style={{ color: isCustomTemplate ?  : '#fff' }}
          style={{
            color: getStyleValue('heading', 'color', theme.c3),
            fontFamily: getStyleValue('heading', 'font', ''),
            textAlign: getStyleValue('heading', 'alignment', ''),
          }}
          className={`sm:text-[0.8rem] md:text-[1.5rem] font-bold ml-6 md:ml-16 xl:ml-28 w-full ${getStyleValue(
            'heading',
            'font_format',
            'font-bold',
          )}`}
        >
          {data?.name}
          {contentData.length > 14 && (
            <span
              style={{ cursor: 'pointer' }}
              className={
                'text-blue-400 text-xs md:text-md  ml-2 cursor-pointer hover:opacity z-30 mt-3'
              }
              onClick={handleSeeMore}
            >
              {/* {t('see_more')} */}
              See More
            </span>
          )}
        </p>
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={contentData.length}
        visibleSlides={visibleSlides}
        step={5}
      >
        <div
          className={
            isEmpty(contentData)
              ? 'animate-pulse swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[16rem]'
              : 'swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[16rem]'
          }
          style={{
            backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
          }}
        >
          <Slider
            style={{
              minHeight: 'unset',
              maxHeight: '280px',
            }}
            className="audio-swimlane"
          >
            {contentData.map((item, index) => {
              return (
                <Slide key={index} index={index} className="">
                  <div className="pt-2 md:pt-4 lg:pt-8">
                    <ContentCardAudio
                      background={getStyleValue('card_style', 'background_color', theme.c1)}
                      textStyle={{
                        color: getStyleValue('card_style', 'color', theme.c4),
                        fontFamily: getStyleValue('card_style', 'font', ''),
                      }}
                      fontFormat={getStyleValue('card_style', 'font_format', 'font-bold')}
                      data={item}
                      type={'content'}
                      isEdit={isEdit}
                    />
                  </div>
                </Slide>
              )
            })}
          </Slider>
          {isBrowser && (
            <>
              {contentData.length > visibleSlides ? (
                <ButtonBack>
                  <IoIosArrowBack className="left-Swimlane-arrow " />
                </ButtonBack>
              ) : null}
              {contentData.length > visibleSlides ? (
                <ButtonNext>
                  <IoIosArrowForward className="right-Swimlane-arrow " />
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(AudioSwimlane)
