import { isEmpty } from 'lodash'

const getIdFromPathName = (pathname) => {
  let spl = pathname.split('/')
  return spl.length > 0 ? spl[spl.length - 1] : ''
}

export const getCurrentFormPath = (forms, pathname) => {
  let entity = []
  let staticPathToEntityMap = {
    '/': 'HOME',
    '/home': 'HOME',
    '/login': 'LOGIN',
    '/signup': 'REGISTER',
    '/artists': 'ARTIST_LIST',
    '/venues': 'VENUE_LIST',
    '/subscriptions': 'PRICING',
  }
  entity = staticPathToEntityMap[pathname]
  if (entity) {
    let entityForms = forms.filter((form) => form.entities.type === entity)
    return !isEmpty(entityForms) ? entityForms[0] : {}
  }

  let dynamicPathToEntityMap = {
    CATEGORY: 'CATEGORY',
    ARTIST_DETAIL: 'ARTIST_DETAIL',
    VENUE_DETAIL: 'VENUE_DETAIL',
    CONTENT_DETAIL: 'CONTENT_DETAIL',
    CONTENT_DETAIL_PLAYER: 'CONTENT_DETAIL_PLAYER',
    PLAYER: 'PLAYER',
    PAGES: 'PAGES',
    IPTV: 'IPTV Channel',
    ALL_IPTV: 'All IPTV Channels',
  }
  if (/^\/category\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [dynamicPathToEntityMap.CATEGORY]
  } else if (/^\/artist\/detail\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [dynamicPathToEntityMap.ARTIST_DETAIL]
  } else if (/^\/venue\/detail\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [dynamicPathToEntityMap.VENUE_DETAIL]
  } else if (/^\/content\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [
      dynamicPathToEntityMap.CONTENT_DETAIL,
      dynamicPathToEntityMap.PLAYER,
      dynamicPathToEntityMap.CONTENT_DETAIL_PLAYER,
    ]
  } else if (/^\/player\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [dynamicPathToEntityMap.PLAYER, dynamicPathToEntityMap.CONTENT_DETAIL_PLAYER]
  } else if (/^\/custom\/[0-9a-zA-Z]+/.test(pathname)) {
    entity = [dynamicPathToEntityMap.PAGES]
    let entityForms = forms.filter((form) => entity.includes(form.entities.type))
    if (!isEmpty(entityForms)) {
      let pageUrls = entityForms[0]?.entities?.data.map((d) => d.page_url)
      return pageUrls.includes(pathname) ? entityForms[0] : {}
    } else {
      return {}
    }
  }
  if (entity) {
    let entityForms = forms.filter((form) => entity.includes(form.entities.type))
    if (!isEmpty(entityForms)) {
      let current_form = {}
      entityForms.forEach((form) => {
        let formIds = form?.entities?.data.map((d) => d.id)
        if (formIds.includes(getIdFromPathName(pathname))) {
          current_form = form
        }
      })
      return current_form
    } else {
      return {}
    }
  }
}
