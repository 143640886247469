import React, { useContext, useEffect, useReducer, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import './subscriptionStyle.scss'
import { SubscriptionContext } from '../../context/subscription'
import SubscriptionSlide from './subscriptionSlide'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const initialState = {
  slideIndex: 0,
  slideLength: 0,
}

const slidesReducer = (state, event) => {
  if (event.type === 'NEXT') {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % state.slideLength,
    }
  }
  if (event.type === 'PREV') {
    return {
      ...state,
      slideIndex: state.slideIndex === 0 ? state.slideLength - 1 : state.slideIndex - 1,
    }
  }
  if (event.type === 'LENGTH') {
    return {
      ...state,
      slideLength: event.payload.size,
    }
  }
}

const Subscription = ({ theme }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(slidesReducer, initialState)
  const { setOpenSubscriptionModal, availableSubscription, subscriptions } =
    useContext(SubscriptionContext)
  const { isCustomTemplate } = useContext(AppContext)

  const [allowedSub, setAllowedSub] = useState()

  useEffect(() => {
    if (!isEmpty(availableSubscription)) {
      dispatch({
        type: 'LENGTH',
        payload: {
          size: !isEmpty(subscriptions) ? subscriptions.length : availableSubscription.length,
        },
      })

      if (!isEmpty(subscriptions)) {
        setAllowedSub(availableSubscription.filter((item) => subscriptions.includes(item._id)))
      } else {
        setAllowedSub(availableSubscription)
      }
    }
  }, [availableSubscription, subscriptions])

  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
      >
        <div className={`relative lg:w-3/4 xl:w-2/5 my-6 mx-auto`}>
          <div
            style={{
              backgroundColor: isCustomTemplate ? `${theme.c10}95` : 'rgb(27, 36, 47,0.95)',
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              border: '',
            }}
            className="shadow-lg relative flex flex-col w-full p-12 "
          >
            <div className=" py-2 object-right-top">
              <button
                className="p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={() => setOpenSubscriptionModal(false)}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" h-6 w-6 text-xl xl:text-2xl block outline-none focus:outline-none"
                >
                  ×
                </span>
              </button>
              <div className="flex justify-center">
                <h3
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" text-xl md:text-2xl xl:text-3xl  font-semibold"
                >
                  {t('select_your_plan')}
                </h3>
              </div>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className=" flex justify-center text-center"
              >
                {t('choose_plan_friends')}
              </p>
            </div>
            <div className="relative mt-2 w-full py-12 md:px-32 flex justify-center overflow-hidden">
              {!isEmpty(allowedSub) ? (
                <div className="slides">
                  <button onClick={() => dispatch({ type: 'PREV' })}>‹</button>

                  {[...allowedSub, ...allowedSub, ...allowedSub].map((slide, i) => {
                    let offset = state.slideLength + (state.slideIndex - i)
                    return <SubscriptionSlide slide={slide} offset={offset} key={i} />
                  })}
                  <button onClick={() => dispatch({ type: 'NEXT' })}>›</button>
                </div>
              ) : (
                <div className="animate-pulse">
                  <SlideLoading theme={theme} isCustomTemplate={isCustomTemplate} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(Subscription)

function SlideLoading({ theme, isCustomTemplate }) {
  return (
    <div
      style={{
        backgroundColor: '#223347',
        borderColor: isCustomTemplate ? theme.c3 : '',
        color: isCustomTemplate ? theme.c4 : '#fff',
      }}
      className="h-64 w-48 mr-6 mt-8 border rounded-xl duration-500"
    >
      <div style={{ backgroundColor: '#344d6b' }} className="px-4 py-12 rounded-xl text-center">
        <p className="text-sm"> loading...</p>
      </div>
    </div>
  )
}
