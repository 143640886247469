import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import ShareIcon from '../../assets/share-icon-black.svg'
import AddWatchlist from '../../assets/add-watchlist-black.svg'
import AddFavourite from '../../assets/add-favourite-black.svg'
import AddedWatchlist from '../../assets/added-watchlist-black.svg'
import AddedFavourite from '../../assets/added-favourite-black.svg'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { ContentContext } from '../../context/content'

const menuBtnStyles = {
  fontSize: 'max(1.145vw,16px)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  //   background: 'gray',
  paddingTop: '8px',
  paddingBottom: '10px',
  borderBottom: '3px solid',
}

const PlayerPageBottom = ({ options = [], onShare, contentId }) => {
  const [menuState, setMenuState] = useState(0)
  const { t } = useTranslation()
  const { isLogin } = useContext(AppContext)
  const { isInFavoriteList, isInWatchList, addRemoveFavouriteList, addRemoveWatchList } =
    useContext(ContentContext)

  const renderComponent = (num) => {
    return options[num]?.child
  }

  useEffect(() => {
    setMenuState(0)
  }, [contentId])
  return (
    <div className="h-[470px] lg:h-full ">
      <div
        className="w-full flex justify-between items-start"
        style={{
          position: 'relative',
          zIndex: '10',
          borderBottom: '1px solid #707070',
        }}
      >
        <div
          style={{
            overflowX: 'auto',
          }}
          className="relative w-full lg:w-auto h-full no-scrollbar"
        >
          <div className="flex justify-center md:justify-start items-end h-full md:gap-x-[min(5vw,71px)] gap-x-[43px] w-full lg:px-0 px-4 ">
            {!isEmpty(options) &&
              options.map((option, idx) => (
                <button
                  key={idx}
                  style={{
                    ...menuBtnStyles,
                    color: menuState === idx ? '#09e9e9' : '#cecece',
                    borderBottomColor: menuState === idx ? '#09e9e9' : 'transparent',
                    paddingBottom: '10.5px',
                    paddingTop: '8px',
                  }}
                  className="flex-1 lg:flex-none text-xs lg:text-base h-full"
                  onClick={() => setMenuState(idx)}
                >
                  {option.title}
                </button>
              ))}
          </div>
        </div>
        <div className="hidden lg:flex  items-end">
          <img
            src={ShareIcon}
            className=" hidden lg:block lg:h-[34px] lg:w-[34px] cursor-pointer"
            onClick={onShare}
            title={t('share')}
            alt="share"
          />

          {isLogin && (
            <div className="flex  justify-between ">
              <div className=" cursor-pointer ml-2 xl:ml-4">
                {isInWatchList(contentId) ? (
                  <img
                    src={AddedWatchlist}
                    className=" hidden lg:block lg:h-[34px] lg:w-[34px]"
                    onClick={() => addRemoveWatchList(contentId)}
                    alt="AddedWatchlist"
                  />
                ) : (
                  <img
                    src={AddWatchlist}
                    className=" hidden lg:block lg:h-[34px] lg:w-[34px]"
                    onClick={() => addRemoveWatchList(contentId)}
                    alt="AddWatchlist"
                  />
                )}
              </div>
              <div className="cursor-pointer ml-2 xl:ml-4 ">
                {isInFavoriteList(contentId) ? (
                  <img
                    src={AddedFavourite}
                    className=" hidden lg:block lg:h-[34px] lg:w-[34px] "
                    onClick={() => addRemoveFavouriteList(contentId)}
                    alt="AddedFavourite"
                  />
                ) : (
                  <img
                    src={AddFavourite}
                    className=" hidden lg:block lg:h-[34px] lg:w-[34px] "
                    onClick={() => addRemoveFavouriteList(contentId)}
                    alt="AddFavourite"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ border: '2px solid red', borderColor: 'transparent' }}
        className="w-full lg:px-0 px-4 relative text-white scrollbar-class aspect-[370/404] lg:aspect-[1087/567]"
      >
        {renderComponent(menuState)}
      </div>
    </div>
  )
}

export default PlayerPageBottom
