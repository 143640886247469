import useAxios from 'axios-hooks'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { DETAIL_CONTENT } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { ContentContext } from '../../context/content'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { getImage } from '../../utils/helper'
import PlayerPageBottom from './playerPageBottom'
import ReviewsListing from '../../components/reviewRating'
import CommentListing from '../../components/comments'
import ShareModal from '../../components/shareModal/shareModal'
import { withTheme } from 'theming'
import PlayerPageAbout from './playerPageAbout'
import PlayerPageSidebar from './playerPageSidebar'
import VerticalDropdownIcon from '../../assets/vertical-dropdown-icon.svg'
import ShareIcon from '../../assets/share-icon-black.svg'
import AddWatchlist from '../../assets/add-watchlist-black.svg'
import AddFavourite from '../../assets/add-favourite-black.svg'
import AddedWatchlist from '../../assets/added-watchlist-black.svg'
import AddedFavourite from '../../assets/added-favourite-black.svg'
import BasicContentPlayer from '../../components/template/templatePlayers/basicContentPlayer'
import AudioPlayer from '../../components/audioPlayer'
import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import { encodeURI } from 'js-base64'

const PlayerPage = ({ theme }) => {
  const contentId = useParams().contentId

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isCustomTemplate, isLogin, setLoader, accountObj, modulePermissions } =
    useContext(AppContext)
  const {
    isInFavoriteList,
    isInWatchList,
    addRemoveFavouriteList,
    addRemoveWatchList,
    setIsVideoPlaying,
  } = useContext(ContentContext)

  const [detailObj, setDetailObj] = useState()
  const [shareModal, setShareModal] = useState(false)
  const [shareDropdown, setShareDropdown] = useState(false)

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    contentDetailCall()
    setIsVideoPlaying(true)
  }, [contentId])

  useEffect(() => {
    setLoader(isEmpty(detailObj) && contentLoading)
  }, [contentLoading])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setDetailObj(contentObj)
    }
  }, [contentDetail])

  let menuComponents = [
    {
      title: t('about'),
      child: <PlayerPageAbout detailObj={detailObj} contentId={contentId} />,
      id: 'about',
    },
    {
      title: t('reviews'),
      child: <ReviewsListing type={'content'} id={contentId} title={detailObj?.title} />,
      id: 'review',
    },
    {
      title: t('comments'),
      child: <CommentListing type={'content'} id={contentId} theme={theme} />,
      id: 'review',
    },
  ]

  const handleCategoryClick = (id, name) => {
    const encoded = btoa(JSON.stringify(createSystemDefinedFilter('category', [id])))
    navigate(`/category/${id}?category=${encodeURI(name)}&filters=${encoded}`)
  }

  return (
    <div className="flex flex-col items-center justify-center md:pt-4">
      <div className="w-full relative">
        <div className="w-full flex justify-center items-center z-10 lg:bg-transparent ">
          <div
            className=" flex flex-col z-10 lg:w-[88.5%] w-full  lg:bg-transparent  justify-between"
            style={{
              outline: '2px solid transparent',
            }}
          >
            <div
              style={{
                backgroundColor: '#000000',
                color: '#fff',
              }}
              className="w-full z-10 hidden lg:flex flex-col justify-center lg:rounded-xl overflow-hidden aspect-[390/167] lg:aspect-[1699/120] xl:px-[1.5625vw]"
            >
              <p
                style={{ marginBlockEnd: '0' }}
                className="font-bold 2xl:text-3xl lg:text-2xl text-xl line-clamp-1 "
              >
                {detailObj?.title}
              </p>
              <div style={{ marginTop: '0.7vw' }} className="flex items-center ">
                <div className="lg:flex hidden">
                  {!isEmpty(detailObj) &&
                    [...detailObj?.category]
                      .filter((ele, idx) => idx < 4)
                      .map((item, index) => (
                        <p
                          style={{
                            marginBlockEnd: '0',
                            background: '#636363',
                            padding: '2px 5px 2px 5px',
                            marginLeft: index ? '0.625vw' : '',
                          }}
                          className="text-sm rounded line-clamp-1 cursor-pointer"
                          onClick={() => handleCategoryClick(item.id, item.name)}
                          key={index}
                        >
                          {item.name}
                        </p>
                      ))}
                </div>
                {!isEmpty(detailObj?.content_rating) ? (
                  <div
                    style={{ marginLeft: '1.625vw' }}
                    className="flex items-center justify-center pr-4"
                  >
                    {detailObj?.content_rating?.icon?.s3_key && (
                      <div className="mr-1">
                        <img
                          src={getImage(
                            accountObj?.cf_domain_name,
                            detailObj?.content_rating?.icon?.s3_key,
                          )}
                          className="w-6 h-6 "
                          alt="Rating"
                        />
                      </div>
                    )}
                    <p style={{ color: '#fff' }} className="text-sm font-semibold">
                      {detailObj?.content_rating?.name}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: '0.7vw' }} className=" lg:flex justify-between ">
              <div className="w-full lg:w-[64%]">
                <div
                  style={{ aspectRatio: '1.777' }}
                  className="flex justify-center items-center bg-black rounded-md w-full overflow-hidden"
                >
                  {!isEmpty(detailObj) ? (
                    detailObj?.asset_type === 'AUDIO' ? (
                      <AudioPlayer contentId={contentId} detailObj={detailObj} />
                    ) : (
                      <BasicContentPlayer data={{ id: contentId }} controlBar={true} />
                    )
                  ) : null}
                </div>
                <div className="w-full block lg:hidden text-white px-4 mt-2 pb-4">
                  <p
                    style={{ marginBlockEnd: '0', color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className="font-bold 2xl:text-3xl lg:text-2xl text-xl line-clamp-1 "
                  >
                    {detailObj?.title}
                  </p>
                  <div
                    style={{ marginTop: '0.7vw' }}
                    className="flex justify-between items-center relative"
                  >
                    <div className="flex">
                      {!isEmpty(detailObj) &&
                        [...detailObj?.category]
                          .filter((ele, idx) => idx < 4)
                          .map((item, index) => (
                            <p
                              style={{
                                marginBlockEnd: '0',
                                background: '#636363',
                                padding: '2px',
                                marginLeft: index ? '4px' : '',
                              }}
                              className="text-[10px] rounded line-clamp-1 cursor-pointer"
                              onClick={() => handleCategoryClick(item.id, item.name)}
                              key={index}
                            >
                              {item.name}
                            </p>
                          ))}
                    </div>
                    {!isEmpty(detailObj?.content_rating) ? (
                      <div
                        style={{ marginLeft: '1.625vw' }}
                        className="flex items-center justify-center pr-4"
                      >
                        {detailObj?.content_rating?.icon?.s3_key && (
                          <div className="mr-1">
                            <img
                              src={getImage(
                                accountObj?.cf_domain_name,
                                detailObj?.content_rating?.icon?.s3_key,
                              )}
                              className="w-5 h-5 "
                              alt="Rating"
                            />
                          </div>
                        )}
                        <p
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className="text-[10px] font-semibold line-clamp-1"
                        >
                          {detailObj?.content_rating?.name}
                        </p>
                      </div>
                    ) : null}
                    <div className="">
                      <img
                        src={VerticalDropdownIcon}
                        alt="VerticalDropdownIcon"
                        className="cursor-pointer w-1 h-3"
                        onClick={() => {
                          setShareDropdown(!shareDropdown)
                        }}
                        onBlur={() => {
                          setShareDropdown(!shareDropdown)
                        }}
                      />
                      {shareDropdown && (
                        <div
                          className="absolute right-0 flex flex-col items-center px-2"
                          style={{
                            background: theme.c1,
                            boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.25)',
                            border: 'solid 0.3px #374454',
                          }}
                        >
                          <img
                            src={ShareIcon}
                            className=" cursor-pointer w-5 h-5 mt-1"
                            onClick={() => {
                              setShareModal(true)
                            }}
                            title={t('share')}
                            alt="share"
                          />

                          {isLogin && (
                            <div className="">
                              <div className=" cursor-pointer mt-1">
                                {isInWatchList(contentId) ? (
                                  <img
                                    src={AddedWatchlist}
                                    className=" w-5 h-5"
                                    onClick={() => addRemoveWatchList(contentId)}
                                    alt="AddedWatchlist"
                                  />
                                ) : (
                                  <img
                                    src={AddWatchlist}
                                    className=" w-5 h-5"
                                    onClick={() => addRemoveWatchList(contentId)}
                                    alt="AddWatchlist"
                                  />
                                )}
                              </div>
                              <div className="cursor-pointer mt-1 mb-1">
                                {isInFavoriteList(contentId) ? (
                                  <img
                                    src={AddedFavourite}
                                    className=" w-5 h-5 "
                                    onClick={() => addRemoveFavouriteList(contentId)}
                                    alt="AddedFavourite"
                                  />
                                ) : (
                                  <img
                                    src={AddFavourite}
                                    className=" w-5 h-5 "
                                    onClick={() => addRemoveFavouriteList(contentId)}
                                    alt="AddFavourite"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" w-full xl:mt-5 hidden lg:block">
                  <PlayerPageBottom
                    options={
                      modulePermissions?.rating
                        ? menuComponents
                        : menuComponents.filter((info) => info.id !== 'review')
                    }
                    onShare={() => {
                      setShareModal(true)
                    }}
                    contentId={contentId}
                  />
                </div>
              </div>
              <div className="w-full lg:w-[34.9%] ">
                <PlayerPageSidebar detailObj={detailObj} contentId={contentId} />
              </div>
              <div className=" w-full  block lg:hidden">
                <PlayerPageBottom
                  options={
                    modulePermissions?.rating
                      ? menuComponents
                      : menuComponents.filter((info) => info.id !== 'review')
                  }
                  onShare={() => {
                    setShareModal(true)
                  }}
                  contentId={contentId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {shareModal && (
        <ShareModal
          onClose={() => setShareModal(false)}
          link={window.location.href}
          detailObj={detailObj}
        />
      )}
    </div>
  )
}

export default withTheme(PlayerPage)
