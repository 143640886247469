import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/app'
import TrailerPlayer from '../../container/player/trailerPlayer'
import AddFavorite from '../../assets/add-to-favourites-new.svg'
import AddedFavorite from '../../assets/added-to-favourites-new.svg'
import AddWatchList from '../../assets/add-to-watchlist-new.svg'
import AddedWatchList from '../../assets/added-to-watchlist-new.svg'
import PlayIcon from '../../assets/play-icon-new.svg'
import RatingIcon from '../../assets/rating-star-icon.svg'
import ShareIcon from '../../assets/share-new-icon.svg'
import { ContentContext } from '../../context/content'
import { convertDuration, getImage } from '../../utils/helper'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import './hoverCard.css'

const NewContentCardHover = (props) => {
  const { accountObj, setShareModalData } = useContext(AppContext)
  const { isInFavoriteList, isInWatchList, addRemoveFavouriteList, addRemoveWatchList } =
    useContext(ContentContext)

  const navigate = useNavigate()
  const { data, type, position, total, visibleSlides = 5, isVisible } = props

  const getLink = () => {
    if (data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST') {
      return `/playlist/${data.content_id}`
    }
    if (data.video_type === 'MPVP' || data.audio_type === 'MPAP') {
      return `/parent-content/${data.content_id}`
    } else {
      return `/content/${data.content_id}`
    }
  }

  const calcLeftRightPosition = () => {
    let styleObj = {
      left: '50%',
      transform: 'translateX(-50%)',
    }
    if (position % visibleSlides === 0 || position === total) {
      return {
        right: 0,
      }
    }
    if (position % visibleSlides === 1) {
      return {
        left: 0,
      }
    }

    return styleObj
  }

  const handlePlayNow = () => {
    if (data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST') {
      navigate(`/playlist/${data.content_id}`)
      return
    }
    if (data.video_type === 'MPVP' || data.audio_type === 'MPAP') {
      navigate(`/parent-content/${data.content_id}`)
    } else {
      if (accountObj?.od_resource_allowances?.player_page) {
        navigate(`/content-watch/${data.content_id}`)
        return
      }
      navigate(`/content/${data.content_id}?watch=true`)
      return
    }
  }

  return (
    <div
      style={{
        background: '#000000',
        aspectRatio: 1.207,
        width: `min(27.7698vw ,425px)`,
        height: 'min(23vw ,352px)',
        top: '-30px',
        ...calcLeftRightPosition(),
      }}
      className=" rounded-lg absolute hover:scale-101 transform ease transition duration-500 overflow-hidden z-50"
    >
      <div style={{ height: '67.33%' }} className="relative cursor-pointer new-hover-card-detail">
        {isVisible ? (
          <TrailerPlayer
            height={'100%'}
            poster={getImage(accountObj?.cf_domain_name, data.thumbnail)}
            preview={data.preview}
            quality={360}
            type={'HOVER'}
            redirect={() => navigate(getLink())}
            videoType={data.video_type}
            stream={data.stream}
          />
        ) : null}
      </div>
      <div
        style={{ height: '32%' }}
        className="px-4 pt-2 pb-3 text-left z-10 new-hover-card-detail"
      >
        <p
          className={` leading-tight line-clamp-1 font-bold`}
          style={{ fontSize: '15px', color: '#e3e2e2' }}
        >
          {type === 'content' ? data.title : data.name}
        </p>
        <div
          style={{ margin: '11px 0px', color: '#cccccc', fontSize: '12px' }}
          className="flex items-center "
        >
          {!isEmpty(data?.content_rating) && (
            <>
              {data?.content_rating?.name}
              <div
                style={{ width: '5px', height: '5px', background: '#cccccc' }}
                className="rounded-full mx-3"
              />
            </>
          )}
          {!isEmpty(data?.category) && <>{data?.category[0]?.name}</>}
          {data?.duration_in_s > 0 && (
            <>
              <div
                style={{ width: '5px', height: '5px', background: '#cccccc' }}
                className="rounded-full mx-3"
              />
              {convertDuration(data?.duration_in_s)}
            </>
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <div
              className="cursor-pointer mr-3"
              style={{ width: '34px', height: '34px' }}
              onClick={handlePlayNow}
            >
              <img src={PlayIcon} alt="PlayIcon" className="w-full h-full" />
            </div>
            <div
              className="cursor-pointer mx-3"
              // onClick={() => console.log('PlayIcon')}
              style={{ width: '24px', height: '24px' }}
              onClick={() => addRemoveWatchList(data.content_id)}
            >
              {isInWatchList(data.content_id) ? (
                <img src={AddedWatchList} alt="AddedWatchList" className="w-full h-full" />
              ) : (
                <img src={AddWatchList} alt="AddWatchList" className="w-full h-full" />
              )}
            </div>
            <div
              className="cursor-pointer mx-3"
              style={{ width: '24px', height: '24px' }}
              onClick={() => addRemoveFavouriteList(data.content_id)}
            >
              {isInFavoriteList(data.content_id) ? (
                <img src={AddedFavorite} alt="AddedFavorite" className="w-full h-full" />
              ) : (
                <img src={AddFavorite} alt="AddFavorite" className="w-full h-full" />
              )}
            </div>
            <div
              className="cursor-pointer mx-3"
              style={{ width: '24px', height: '24px' }}
              onClick={() =>
                setShareModalData({
                  onClose: () => setShareModalData({}),
                  link: `${window.location.origin}${getLink()}`,
                  detailObj: data,
                })
              }
            >
              <img src={ShareIcon} alt="ShareIcon" className="w-full h-full" />
            </div>
          </div>
          <div className="flex items-center text-xs" style={{ color: '#cccccc' }}>
            {data?.average_rating ? (
              <>
                <img className="cursor-pointer mx-1" src={RatingIcon} alt="RatingIcon" />
                {Number(data?.average_rating).toFixed(1)}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(NewContentCardHover)
