import React, { useContext, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const DiscountModal = ({ discountData, onCancel, getEstimation, currency, theme }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const [discountCode, setDiscountCode] = useState('')
  const { t } = useTranslation()
  const handleApplyCoupon = () => {
    if (discountCode.trim().length === 0) return
    getEstimation(discountCode.trim(), '')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleApplyCoupon()
    }
  }
  const applyButtonCss = 'my-1 px-2  md:px-5 py-1 rounded-lg focus:outline-none'
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-5/6 md:w-3/5 xl:w-5/12 my-2 mx-auto">
          <div
            style={{
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              border: `1px solid ${theme.c5}`,
              backgroundColor: isCustomTemplate ? theme.c10 : '',
            }}
            //
            className={` ${
              !isCustomTemplate && 'bg-gradient-to-tr from-white via-blue-200 to-gray-500'
            } shadow-lg relative flex flex-col md:w-full p-5 `}
          >
            <div className="object-right-top">
              <button className="float-right mr-2" onClick={onCancel}>
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#000' }}
                  className=" h-6 w-6 text-2xl"
                >
                  ×
                </span>
              </button>
              <div className="flex justify-start">
                <h4
                  style={{ color: isCustomTemplate ? theme.c4 : '#000' }}
                  className=" text-lg md:text-2xl  font-semibold"
                >
                  {t('available_discounts')}
                </h4>
              </div>
            </div>
            <div className=" relative mt-2 w-full md:py-4 overflow-hidden">
              <div className="flex justify-center">
                <input
                  style={{
                    color: isCustomTemplate ? theme.c5 : 'rgb(107,114,128)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(107,114,128)',
                  }}
                  className="md:pl-2 text-sm md:text-md bg-transparent focus:bg-opacity-50 border-b-2
                   focus:outline-none w-2/3"
                  type="text"
                  placeholder="Have a Voucher Code"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <button
                  style={{ border: '1px solid', backgroundColor: 'white' }}
                  className={`bg-transparent ml-2 ${applyButtonCss} text-green-600`}
                  onClick={handleApplyCoupon}
                >
                  {t('apply')}
                </button>
              </div>
              {!isEmpty(discountData) ? (
                discountData.map((info, index) => (
                  <div
                    style={{ backgroundColor: isCustomTemplate ? theme.c7 : '#fff' }}
                    className="w-full cursor-pointer shadow rounded-lg px-3 md:px-8 py-4 relative z-30 my-4 border border-gray-400"
                    key={index}
                  >
                    <div className="flex items-center justify-between">
                      <h2
                        style={{ color: isCustomTemplate ? theme.c6 : 'rgb(31,41,55)' }}
                        className="text-md md:text-xl font-semibold "
                      >
                        {info.value_type === 'percentage'
                          ? `Get ${info.value}% off up to ${currency} ${info.max_value}.`
                          : `Flat $ ${info.value} Off.`}
                      </h2>
                      <button
                        style={{ border: '1px solid' }}
                        className={`bg-transparent ${applyButtonCss} text-green-600`}
                        onClick={() => getEstimation('', info._id)}
                      >
                        {t('apply')}
                      </button>
                    </div>
                    <p
                      style={{ color: isCustomTemplate ? theme.c6 : 'rgb(96,165,250)' }}
                      className="text-sm md:text-base leading-6 mt-4"
                    >
                      *{info.description}
                    </p>
                  </div>
                ))
              ) : (
                <p
                  style={{ color: isCustomTemplate ? theme.c6 : 'rgb(55,65,81)' }}
                  className="flex justify-center mt-4 text-gray-700"
                >
                  {t('no_discounts_available')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(DiscountModal)
