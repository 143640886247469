import React, { useContext, useState } from 'react'
import { AppContext } from '../../context/app'
import './contentDetail.css'
import useAxios from 'axios-hooks'
import { isEmpty } from 'lodash'
import { RELATED_CONTENT } from '../../utils/apiUrls'
import { withTheme } from 'theming'
import InComponentLoader from '../../components/InComponentLoader'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { encodeURI } from 'js-base64'
import NewContentCard from '../../components/contentCard/newContentCard'
import NewContentCardHover from '../../components/contentCard/newContentCardHover'

const RelatedVideos = ({ theme, contentId }) => {
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const { isBrowser, isCustomTemplate } = useContext(AppContext)
  let navigate = useNavigate()
  const { t } = useTranslation()

  const [{ data: relatedVideos, loading: relatedVideosLoading }] = useAxios({
    url: `${RELATED_CONTENT}${contentId}`,
    method: 'post',
    data: { filters: {} },
  })

  return (
    <>
      <InComponentLoader loading={relatedVideosLoading} />
      <div className="py-10 lg:py-10 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-x-[43px]">
        {relatedVideos &&
          !isEmpty(relatedVideos?.data) &&
          relatedVideos.data.slice(0, 8).map((content, index) => (
            <div key={index}>
              <div
                style={{ display: hoverCardIndex === index && isBrowser ? 'block' : 'none' }}
                className="relative"
                onMouseLeave={() => setHoverCardIndex('')}
              >
                <NewContentCardHover
                  data={content}
                  type={'content'}
                  visibleSlides={4}
                  position={index + 1}
                  total={8}
                  isVisible={hoverCardIndex === index && isBrowser}
                />
              </div>

              <div
                className="relative"
                style={{ display: hoverCardIndex === index && isBrowser ? 'none' : 'block' }}
                onMouseOver={() => setHoverCardIndex(index)}
              >
                <NewContentCard data={content} type={'content'} />
              </div>
            </div>
          ))}
        {relatedVideos && isEmpty(relatedVideos.data) && !relatedVideosLoading && (
          <p
            className={'text-md md:text-md font-bold mt-5 '}
            style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
          >
            {t('no_related_contents_available')}
          </p>
        )}
      </div>
      {relatedVideos && !isEmpty(relatedVideos.data) && relatedVideos.data.length > 8 && (
        <div className={'flex justify-center mt-5'}>
          <button
            className="px-4 py-1 ml-2 md:text-sm"
            style={{
              color: '#fff',
              backgroundColor: '#1976d2',
              borderRadius: '6px',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              const encoded = encodeURI(JSON.stringify(relatedVideos.filters))
              navigate(`/content/filter/${encodeURI(t('Related videos'))}?filters=${encoded}`)
            }}
          >
            {t('view_more_related_contents')}
          </button>
        </div>
      )}
    </>
  )
}

export default withTheme(RelatedVideos)
