import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppContext } from '../../context/app'
import { isEmpty, PAGE_SIZE } from '../../utils/helper'
import useAxios from 'axios-hooks'
import { CONTENT_SEARCH } from '../../utils/apiUrls'
import ContentListComponent from '../contentListing/contentListComponent'
import { useTranslation } from 'react-i18next'
import ArtistCard from '../../components/artistCard'
import VenueCard from '../../components/venueCard'
import { withTheme } from 'theming'

let totalRecords = 0
let currentPage = 1
let apiHit = false
const Search = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  let searchTerm = searchParams.get('search_text')
  const [multiToggle, setMultiToggle] = useState('CONTENT')
  const [dataList, setDataList] = useState([])
  const [artistList, setArtistList] = useState([])
  const [venueList, setVenueList] = useState([])
  const { t } = useTranslation()

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_SEARCH,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (!isEmpty(searchTerm)) {
      totalRecords = 0
      currentPage = 1
      setDataList([])
      contentFilterCall({
        params: {
          search_text: searchTerm,
          page: 1,
          page_size: PAGE_SIZE,
        },
      })
    }
  }, [searchTerm])

  useEffect(() => {
    setLoader(contentListLoading)
  }, [contentListLoading])

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList((dataList) => [...dataList, ...contentList.data.content])
      setArtistList(contentList.data.artists)
      setVenueList(contentList.data.venues)
      totalRecords = contentList.data.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [])

  const infiniteScroll = () => {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.8 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1
        contentFilterCall({
          params: {
            search_text: searchTerm,
            page: currentPage,
            page_size: PAGE_SIZE,
          },
        })
      }
    }
  }

  return (
    <>
      <div className="w-full xl:px-20 my- md:mb-5 text-base font-semibold md:text-xl">
        <p
          style={{ color: isCustomTemplate ? theme.c3 : '#fff' }}
          className={' mx-2 mb-3 underline md:text-2xl'}
        >
          {t('search_result_for')} {searchTerm}
        </p>
        <div className="flex justify-start">
          <p
            style={{
              color:
                multiToggle === 'CONTENT'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
              borderColor:
                multiToggle === 'CONTENT'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
            }}
            className={
              multiToggle === 'CONTENT'
                ? ' mx-2 border-b-2 cursor-pointer'
                : ' mx-2 cursor-pointer text-gray-400'
            }
            onClick={() => setMultiToggle('CONTENT')}
          >
            {t('content')}
          </p>
          <p
            style={{
              color:
                multiToggle === 'ARTIST'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
              borderColor:
                multiToggle === 'ARTIST'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
            }}
            className={
              multiToggle === 'ARTIST'
                ? ' mx-2 md:mx-5 border-b-2 cursor-pointer'
                : ' mx-2 md:mx-5 cursor-pointer'
            }
            onClick={() => setMultiToggle('ARTIST')}
          >
            {t('artist')}
          </p>
          <p
            style={{
              color:
                multiToggle === 'VENUE'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
              borderColor:
                multiToggle === 'VENUE'
                  ? isCustomTemplate
                    ? theme.c3
                    : '#fff'
                  : isCustomTemplate
                  ? theme.c4
                  : 'rgb(156,163,175)',
            }}
            className={
              multiToggle === 'VENUE'
                ? ' mx-2 md:mx-5 border-b-2 cursor-pointer'
                : ' mx-2 md:mx-5 cursor-pointer'
            }
            onClick={() => setMultiToggle('VENUE')}
          >
            {t('venue')}
          </p>
        </div>
      </div>
      {multiToggle === 'CONTENT' && <ContentListComponent data={dataList} />}
      {multiToggle === 'ARTIST' && (
        <>
          {!isEmpty(artistList) ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 px-4 py-5 md:px-16 md:py-10 lg:px-24 xl:py-6">
              {artistList &&
                artistList.map((item, index) => <ArtistCard item={item} key={index} />)}
            </div>
          ) : (
            <div className="flex justify-center align-middle max-h-full">
              <p
                style={{ color: isCustomTemplate ? theme.c4 : 'rgb(252,165,165)' }}
                className="text-xl md:text-3xl"
              >
                {' '}
                {t('no_artist_found') + ' '}
                {searchTerm}
              </p>
            </div>
          )}
        </>
      )}
      {multiToggle === 'VENUE' && (
        <>
          {!isEmpty(venueList) ? (
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-10 py-5 xl:px-20 xl:py-6">
              {venueList && venueList.map((item, index) => <VenueCard item={item} key={index} />)}
            </div>
          ) : (
            <div className="flex justify-center align-middle max-h-full">
              <p
                style={{ color: isCustomTemplate ? theme.c4 : 'rgb(252,165,165)' }}
                className="text-xl md:text-3xl"
              >
                {' '}
                {t('no_video_found') + ' '}
                {searchTerm}
              </p>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default withTheme(Search)
