import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import { encodeURI } from 'js-base64'

export const createSlug = (name) => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '-')
}

export const getExternalLink = (URL) => {
  if (URL) {
    return URL.includes('http') ? URL : `http://${URL}`
  }
  return ``
}

export const getTemplateRoutes = (linkObj) => {
  const contentLink = () => {
    if (linkObj?.data?.other?.length) {
      if (linkObj?.data?.other[0]?.value === 'MPVP' || linkObj?.data?.other[0]?.value === 'MPAP') {
        return `/parent-content/${linkObj?.data?.id}`
      }
      if (linkObj?.data?.other[0]?.value === 'PLAYLIST') {
        return `/playlist/${linkObj?.data?.id}`
      }
      return `/content/${linkObj?.data?.id}`
    }
    return ``
  }
  const categoryLink = () => {
    if (linkObj?.data?.name && linkObj?.data?.id) {
      const encoded = btoa(
        JSON.stringify(createSystemDefinedFilter('category', [linkObj?.data?.id])),
      )
      return `/category/${linkObj?.data?.id}?category=${encodeURI(
        linkObj?.data?.name,
      )}&filters=${encoded}`
    }
    return ``
  }

  if (linkObj?.type) {
    let routeMapping = {
      ARTIST_DETAIL: `/artist/detail/${linkObj?.data?.id}`,
      ARTIST_LIST: `/artists`,
      CATEGORY: categoryLink(),
      CONTENT_DETAIL: contentLink(),
      LOGIN: `/login`,
      REGISTER: `/signup`,
      PRICING: `/pricing`,
      VENUE_DETAIL: `/venue/detail/${linkObj?.data?.id}`,
      VENUE_LIST: `/venues`,
      PAGES: linkObj?.data?.page_url,
      HOME: '/',
      ALL_IPTV: '/channels',
      IPTV: `/channel/${linkObj?.data?.id}`,
    }

    return routeMapping[linkObj?.type]
  }
  return ''
}
