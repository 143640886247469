import React, { useContext, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import NewContentCard from '../../components/contentCard/newContentCard'
import NewContentCardHover from '../../components/contentCard/newContentCardHover'

const ContentListComponent = (props) => {
  const { t } = useTranslation()
  const { title, data, noContentText = t('no_content'), theme } = props
  const { isCustomTemplate, isBrowser } = useContext(AppContext)
  const [hoverCardIndex, setHoverCardIndex] = useState('')

  return (
    <div className="min-h-full">
      <div className="text-center xl:mx-20 xl:text-left">
        <p
          className="underline text-md lg:text-2xl mb-2 lg:mt-10 "
          style={{ textTransform: 'capitalize', color: isCustomTemplate ? theme.c3 : '#fff' }}
        >
          {title?.replace(/-/g, ' ')}
        </p>
      </div>
      <div className="py-5 lg:py-10 px-10 md:px-16 lg:px-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
        {!isEmpty(data) &&
          data.map((content, index) => (
            <div key={index}>
              <div
                style={{ display: hoverCardIndex === index && isBrowser ? 'block' : 'none' }}
                className="relative"
                onMouseLeave={() => setHoverCardIndex('')}
              >
                <NewContentCardHover
                  data={content}
                  type={'content'}
                  position={index + 1}
                  total={data.length + 10}
                  isVisible={hoverCardIndex === index && isBrowser}
                />
              </div>
              <div
                className="relative"
                style={{ display: hoverCardIndex === index && isBrowser ? 'none' : 'block' }}
                onMouseOver={() => setHoverCardIndex(index)}
              >
                <NewContentCard data={content} type={'content'} />
              </div>
            </div>
          ))}
      </div>
      {isEmpty(data) && (
        <div className="flex justify-center align-middle max-h-full">
          <p
            style={{ color: isCustomTemplate ? theme.c4 : ' rgb(252,165,165)' }}
            className="text-xl md:text-3xl"
          >
            {noContentText}
          </p>
        </div>
      )}
    </div>
  )
}

export default withTheme(ContentListComponent)
